import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { JwtHelper } from '../jwt.helper';
import { AddinConfigService } from '@outlook-addin/shared';

export enum OutlookLoginMode {
  nonInteractive = 'nonInteractive',
  interactive = 'interactive',
}

export const STORAGE_KEYS = {
  outlookLoginMode: 'outlook_login_mode',
  outlookUsername: 'outlook_username',
  accessToken: 'access_token',
  refreshToken: 'refresh_token',
  accessTokenMicrosoft: 'ms_token',
  refreshTokenMicrosoft: 'ms_refresh_token',
  code: 'code',
  loginErrorMicrosoft: 'ms_login_error_code',
};

export interface IDataCrate<T> {
  data: T;
  errorCode: number;
  errorDescription: string;
  success: boolean;
}

export interface IAccessRefreshTokenData {
  email: string;
  guid: string;
  id: number;
  refreshToken: string;
  token: string;
  username: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  jwtHelper = new JwtHelper();
  accessTokenChanged = new Subject<boolean>();

  constructor(
    private configService: AddinConfigService,
    protected http: HttpClient,
  ) {}

  // #region PROPERTY GETTERS/SETTERS
  get microsoftAccessToken(): string | null {
    return localStorage.getItem(STORAGE_KEYS.accessTokenMicrosoft);
  }

  set microsoftAccessToken(value: string | null) {
    localStorage.setItem(STORAGE_KEYS.accessTokenMicrosoft, value!);
    this.accessTokenChanged.next(true);
  }

  get accessToken(): string | null {
    return localStorage.getItem(STORAGE_KEYS.accessToken);
  }

  set accessToken(value: string | null) {
    localStorage.setItem(STORAGE_KEYS.accessToken, value!);
    this.accessTokenChanged.next(true);
  }

  get refreshToken(): string | null {
    return localStorage.getItem(STORAGE_KEYS.refreshToken);
  }

  set refreshToken(value: string | null) {
    localStorage.setItem(STORAGE_KEYS.refreshToken, value!);
  }

  get microsoftRefreshToken(): string | null {
    return localStorage.getItem(STORAGE_KEYS.refreshTokenMicrosoft);
  }

  set microsoftRefreshToken(value: string | null) {
    localStorage.setItem(STORAGE_KEYS.refreshTokenMicrosoft, value!);
  }

  // #endregion

  clearLocalStorage() {
    localStorage.removeItem(STORAGE_KEYS.refreshToken);
    localStorage.removeItem(STORAGE_KEYS.accessToken);
    localStorage.removeItem(STORAGE_KEYS.code);
    localStorage.removeItem(STORAGE_KEYS.accessTokenMicrosoft);
    localStorage.removeItem(STORAGE_KEYS.refreshTokenMicrosoft);
  }

  isAccessTokenExpired(): boolean {
    if (!this.accessToken) {
      return true;
    }
    return this.jwtHelper.isTokenExpired(this.accessToken);
  }

  isUserLoggedIn() {
    return (
      localStorage.getItem(STORAGE_KEYS.accessToken) &&
      localStorage.getItem(STORAGE_KEYS.refreshToken) &&
      localStorage.getItem(STORAGE_KEYS.accessTokenMicrosoft) &&
      localStorage.getItem(STORAGE_KEYS.refreshTokenMicrosoft) &&
      localStorage.getItem(STORAGE_KEYS.code)
    );
  }

  setOutlookLoginMode(loginMode: OutlookLoginMode) {
    localStorage.setItem(STORAGE_KEYS.outlookLoginMode, loginMode);
  }

  private getTokenToMicrosoft(authCode: string): Observable<string> {
    const url = `${this.configService.value.apiURL}/api/account/oauth-token`;
    return this.http.get(url).pipe(
      map((res) => {
        return (res as unknown as any).data as string;
      }),
    );
  }

  signOut(): Observable<void> {
    const logoutUrl = `${this.configService.value.apiURL}/api/account/oauth-logout`;
    return this.http.get<void>(logoutUrl);
  }
  signIn(): Observable<string> {
    const loginUrl = `${this.configService.value.apiURL}/api/account/oauth-login`;
    const body = {
      authorizationCode: localStorage.getItem(STORAGE_KEYS.code),
      redirectUrl: `https://${window.location.host}/Authorize2`,
      scope: 'Mail.Read offline_access openid profile User.Read',
    };

    return this.http.post(loginUrl, body).pipe(
      map((x) => {
        const result = x as IDataCrate<IAccessRefreshTokenData>;
        this.accessToken = result.data.token;
        this.refreshToken = result.data.refreshToken;
        this.microsoftRefreshToken = result.data.refreshToken;
        return result.data.token;
      }),
      mergeMap((authCode) => {
        return this.getTokenToMicrosoft(authCode).pipe(
          map((tokenToMicrosoft) => {
            localStorage.setItem(STORAGE_KEYS.accessTokenMicrosoft, tokenToMicrosoft);
            return tokenToMicrosoft;
          }),
        );
      }),
    );
  }

  tryRefreshToken(): Observable<string | null> {
    console.log('Try to refresh TOKEN');
    if (!this.refreshToken) {
      return of(null!);
    }
    const url = this.configService.value.apiURL + '/api/account/refresh';

    return this.http
      .post(
        url,
        {
          refreshToken: this.refreshToken,
        },
        undefined,
      )
      .pipe(
        map((res: any) => {
          this.accessToken = res.data.token;
          this.refreshToken = res.data.refreshToken;
          return res.data.token;
        }),
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          throw new Error();
        }),
      );
  }

  tryRefreshMicrosoftToken(): Observable<string | null> {
    if (!this.microsoftRefreshToken) {
      return of(null!);
    }
    const url = this.configService.value.apiURL + '/api/account/refresh-oauth-token';

    return this.http
      .post(
        url,
        {
          scope: 'Mail.Read offline_access openid profile User.Read',
        },
        undefined,
      )
      .pipe(
        map((res: any) => {
          this.microsoftAccessToken = res.data;
          return res.data;
        }),
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          throw new Error();
        }),
      );
  }
}
