import { createReducer, on, Action } from '@ngrx/store';
import * as UserActions from '../actions/current-user.actions';
import { AssistUserSettingsBM } from '@cue/api';
import { Pack } from '../models';

export const initialState: Pack<AssistUserSettingsBM> = {
  loading: false,
  data: null,
};

const reducer = createReducer(
  initialState,
  on(UserActions.loadCurrentUserSettings, (state) => ({
    ...state,
    loading: true,
  })),
  on(UserActions.currentUserSettingsLoaded, (state, { userSettings }) => ({
    ...state,
    loading: false,
    data: userSettings,
  })),
);

export function userSettingsReducer(state: Pack<AssistUserSettingsBM>, action: Action) {
  return reducer(state, action);
}
