<div class="wrapper">
    @for (order of orderList; track order; let i = $index) {
        <div class="accordion" [class.expanded]="!isItemShrinked(i)">
            <div class="accordion-header" (click)="expandShrinkGroup(i)">
                <div class="first-part">
                    <p class="heading">
                        {{ order.code }}
                    </p>
                </div>
                <div class="end-part">
                    <!--          <div class="date">-->
                    <!--            <p>-->
                    <!--              {{ order.created | date: 'EEEE, MMMM d, y, h:mm:ss' }}-->
                    <!--            </p>-->
                    <!--          </div>-->
                    <div class="arrow">
                        <cue-icon [icon]="ICONS.cueArrowDown"></cue-icon>
                    </div>
                </div>
            </div>
            @if (!isItemShrinked(i)) {
                <div class="accordion-content">
                    @if (order.note !== null) {
                        <div class="description">
                            <p>
                                {{ order.note }}
                            </p>
                        </div>
                    }

                    @for (item of order.items; track item) {
                        <div class="order-item">
                            <div class="image-wrapper">
                                <img [src]="item.imageUrl ?? 'assets/images/placeholder-order.svg'" />
                            </div>
                            <div class="text-wrapper">
                                <div class="item-heading">
                                    <p>
                                        {{ item?.displayName }}
                                    </p>
                                </div>
                                <div class="item-inner-count">
                                    @if (item.note) {
                                        <span class="item-description">
                                            {{ item.note }}
                                        </span>
                                    }
                                    @if (!item.note) {
                                        <span class="item-description no-description">
                                            {{ translation.orders.noDescription | transloco }}
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    }
</div>
