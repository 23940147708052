import { createSelector } from '@ngrx/store';
import { selectShared } from './shared.selectors';
import { allResources } from './resources.selectors';
import { schedules } from './schedules.selectors';
import { getRequirements, filter } from './filter.selectors';
import {
  Filter,
  TimelineRoomGroup,
  Schedule,
  TimelineEvent,
  Day,
  TimelineSizes,
  sortByType,
  Pack,
  Requirement,
  FilterAttendee,
} from '../models';
import { allAreas, allAreasHierarchically } from './areas.selectors';
import { add, differenceInMinutes, endOfDay, getDayOfYear, getHours, getMinutes, startOfDay } from 'date-fns';
import { AssistConfigurationBM, fromLocalized, ServerType } from '@cue/api';
import { CalendarAvailability } from '@cue/calendars';
import { getTotallAvailability, distinctBy, scheduleHelper, daysBetweemDates, isDifferent, patchSchedules } from '../utils/index';
import { currentUserSettings } from './current-user.selectors';
import { loadedAttendees } from './filter-attendees.selectors';
import { ConfigService } from '../services';
import { LicensingService } from '@cue/licensing';
import { zonedTimeToUtc } from 'date-fns-tz';

export const timelineSettings = createSelector(selectShared, (state) => state.timelineSettings);

export const timelineSizes = createSelector(timelineSettings, (state) => state.sizes);
export const timelineMode = createSelector(timelineSettings, (s) => s.mode);
export const timelineSortBy = createSelector(timelineSettings, (s) => s.sortBy);

export const eventsForSchedule = (
  schedule: Schedule,
  filter: Filter,
  sizes: TimelineSizes,
  otherAvailabilityViews: string[][],
  maxUt: number,
) => {
  let availabilityView: any[] = schedule.availabilityView.split('');
  // Patch availability view regarding maxUT
  const maxCount = otherAvailabilityViews.length + 1;
  const maxAvailableCount = Math.floor((maxCount * maxUt) / 100);

  for (let i = 0; i < availabilityView.length; i++) {
    let count = 0;
    for (let j = 0; j < otherAvailabilityViews.length; j++) {
      if (otherAvailabilityViews[j][i] == CalendarAvailability.reserved || otherAvailabilityViews[j][i] == CalendarAvailability.tentative) {
        count++;
      }
      if (count + 1 > maxAvailableCount) {
        if (
          availabilityView[i] === CalendarAvailability.free ||
          availabilityView[i] === CalendarAvailability.assignedToOthers ||
          availabilityView[i] === CalendarAvailability.assignedToYou
        ) {
          availabilityView[i] = CalendarAvailability.maxUtilization;
        }
      }
    }
  }

  // Znovu opatchovat podle limitu
  availabilityView = scheduleHelper.patchAvailabilityViewByLimits(availabilityView, filter);

  let lastIndex = 0;
  let lastValue = availabilityView[lastIndex];
  const events: TimelineEvent[] = [];

  const getAvailability = (availability: CalendarAvailability) => {
    return availability === CalendarAvailability.free || availability === CalendarAvailability.freeOutsideWorkingHours
      ? CalendarAvailability.free
      : availability;
  };

  const addition = Math.floor(getMinutes(startOfDay(filter.date)) / 15) * sizes.quarterWidth;
  for (let i = 1; i <= availabilityView.length; i++) {
    if (isDifferent(availabilityView[i], lastValue)) {
      const finalAvailability = getAvailability(availabilityView[i - 1]);
      const event = {
        availabilityView: availabilityView.slice(lastIndex, i).join(''),
        availability: finalAvailability,
        height: sizes.scheduleHeight,
        width: (i - lastIndex) * sizes.quarterWidth,
        left: lastIndex * sizes.quarterWidth + addition,
        duration: (i - lastIndex + 1) * 15,
        top: 0, //(sizes.scheduleHeight + sizes.scheduleMargin * 2) * scheduleIndex,
        start: add(startOfDay(filter.date), { minutes: lastIndex * 15 }),
        end: add(add(startOfDay(filter.date), { minutes: lastIndex * 15 }), {
          minutes: (i - lastIndex) * 15,
        }),
        id: 'schedule:' + schedule.resourceId + ':' + i,
      };

      events.push(event);
      lastIndex = i;
      lastValue = availabilityView[lastIndex];
    }
  }
  return events;
};

export const resourcesData = createSelector(allResources, (s) => s.data);

export const filteredResourcesData = createSelector(
  allResources,
  currentUserSettings,
  allAreas(),
  (packedResources, packedSettings, packedAreas) => {
    const ids = packedAreas.data.map((x) => x.id);
    const filteredIds = packedSettings.data?.location.filteredAreaIds;
    const allowedIds = ids.filter((id) => !filteredIds?.includes(id));
    return packedResources.data?.filter((resource) => allowedIds.includes(resource.mapInfos[0].areaId));
  },
);

export const resourcesLoading = createSelector(allResources, (s) => s.loading);

export const freeColors: { color: string; minPercentage: number }[] = [
  {
    minPercentage: 0,
    color: '#c61a1a',
  },
  {
    minPercentage: 1,
    color: '#66e33b',
  },
  {
    minPercentage: 25,
    color: '#46cb18',
  },
  {
    minPercentage: 50,
    color: '#06a10b',
  },
  {
    minPercentage: 75,
    color: '#1d800e',
  },
  {
    minPercentage: 100,
    color: '#06a10b',
  },
];

export const getFreeColor = (percentage: number) => {
  const succ = freeColors.filter((c) => percentage >= c.minPercentage).map((x) => x.color);
  return succ[succ.length - 1];
};

export const storeData = () =>
  createSelector(
    resourcesData,
    allAreas(),
    allAreasHierarchically(),
    currentUserSettings,
    (resources, areas, allAreasHierarchically, settings) => ({
      resourcesData: resources,
      areas,
      allAreasHierarchically,
      settings,
    }),
  );

export const timeline = (activeLang: string, defaultLang: string) =>
  createSelector(
    filter,
    timelineSizes,
    storeData(),
    resourcesLoading,
    schedules,
    getRequirements,
    timelineSortBy,
    loadedAttendees,
    (
      _filter: any,
      _sizes: TimelineSizes,
      _storeData: any,
      _packedResourcesLoading: boolean | undefined,
      _packedSchedules: Pack<Schedule[]>,
      __requirements: Requirement[],
      _sortBy: 'name asc' | 'name desc' | 'percentage asc' | 'percentage desc' | 'capacity asc' | 'capacity desc',
      _loadedAttendees: Pack<FilterAttendee[]>,
      props: {
        configService: ConfigService;
        licenseService: LicensingService;
        mode: ServerType;
      },
    ) => {
      const patchedSchedules = patchSchedules(
        !_packedSchedules.loading && !_loadedAttendees.loading ? _packedSchedules.data! : [],
        _filter,
        [..._storeData.resourcesData],
        _loadedAttendees.data,
      );

      const sortMap: { sort: sortByType; func: (a: any, b: any) => number }[] = [
        {
          sort: 'name asc',
          func: (a, b) =>
            (fromLocalized(a.resource.displayNameForApp, activeLang, defaultLang) ?? a.resource.name).localeCompare(
              fromLocalized(b.resource.displayNameForApp, activeLang, defaultLang) ?? b.resource.name,
            ),
        },
        {
          sort: 'name desc',
          func: (a, b) =>
            (fromLocalized(b.resource.displayNameForApp, activeLang, defaultLang) ?? b.resource.name).localeCompare(
              fromLocalized(a.resource.displayNameForApp, activeLang, defaultLang) ?? a.resource.name,
            ),
        },
        {
          sort: 'percentage desc',
          func: (a, b) => a.freePercentage - b.freePercentage,
        },
        {
          sort: 'percentage asc',
          func: (a, b) => b.freePercentage - a.freePercentage,
        },
        {
          sort: 'capacity asc',
          func: (a, b) => b.resource.capacity - a.resource.capacity,
        },
        {
          sort: 'capacity desc',
          func: (a, b) => a.resource.capacity - b.resource.capacity,
        },
      ];

      const sortFunction = sortMap.find((x) => x.sort === _sortBy)!.func;
      const ids = _storeData.areas.data.map((x) => x.id);
      const filteredIds = _storeData.settings.data?.location.filteredAreaIds;
      const allowedIds = ids.filter((id) => !filteredIds?.includes(id));
      let _requirements: any[] = [];

      if (__requirements.length === 0) {
        let resourceTypeInfos = props.configService.getFilteredResourceTypes(_storeData.resourcesData, props.licenseService);

        resourceTypeInfos.forEach((rstInfo, rstINdex) => {
          _requirements.push({
            areas: allowedIds,
            id: rstINdex.toString(),
            equipments: [],
            typeName: fromLocalized(rstInfo.name, activeLang, defaultLang),
            typeId: rstInfo.resourceTypeId,
          });
        });
      } else {
        _requirements = __requirements;
      }

      if (_filter.name != null && _filter.name.length > 0) {
        _requirements = [
          {
            areas: allowedIds,
            id: 'name',
            equipments: [],
            typeName: null,
            typeId: null,
          },
          ..._requirements,
        ];
      }

      const groupedResources: TimelineRoomGroup[] = _requirements.map((req, rindex) => ({
        requirement: req,
        height: patchedSchedules.filter((ps) => ps.requirementId === req.id).length * (_sizes.scheduleHeight + _sizes.scheduleMargin * 2),
        timelineSchedules: patchedSchedules
          .filter((ps) => ps.requirementId === req.id)
          .map((ps, psindex) => {
            ps.scheduleItems = ps.scheduleItems.map((si) => {
              const siStart = si.start as unknown as Date;
              const siEnd = si.end as unknown as Date;
              const scopedStart = startOfDay(_filter.date) > siStart ? startOfDay(_filter.date) : siStart;
              const scopedEnd = endOfDay(_filter.date) < siEnd ? endOfDay(_filter.date) : siEnd;
              const diffEndStart = Math.abs(differenceInMinutes(scopedStart, scopedEnd));
              const width = (diffEndStart / 15) * 20;
              const left = Math.abs((differenceInMinutes(startOfDay(_filter.date), scopedStart) / 15) * 20);
              return {
                ...si,
                width: width,
                left: left,
              };
            });

            const rNew = _storeData.resourcesData.find((x: any) => x.id == ps.resourceId);
            const areaId = rNew.mapInfos.length > 0 ? rNew.mapInfos[0].areaId : -1;
            const toSend = patchedSchedules
              .filter((ps) => ps.requirementId === req.id)
              .filter((sched) => {
                const rNewInner = _storeData.resourcesData.find((x: any) => x.id == sched.resourceId);
                const subAreaId = rNewInner.mapInfos.length > 0 ? rNewInner.mapInfos[0].areaId : -1;
                return sched.resourceId !== ps.resourceId && areaId == subAreaId;
              })
              .map((x) => ({
                availabilityView: x.availabilityView.split(''),
                scheduleId: x.scheduleId,
              }));

            const distinctSend = [];
            const map = new Map();
            for (const item of toSend) {
              if (!map.has(item.scheduleId)) {
                map.set(item.scheduleId, true); // set any value to Map
                distinctSend.push({
                  id: item.scheduleId,
                  availabilityView: item.availabilityView,
                });
              }
            }

            const computedEvents = eventsForSchedule(
              ps,
              _filter,
              _sizes,
              distinctSend.map((x) => x.availabilityView),
              _storeData.areas.data.find((area: any) => area.id == areaId)
                ? _storeData.areas.data.find((area: any) => area.id == areaId).maxUt
                : 100,
            );
            const totallDuration = computedEvents
              .filter((e) => e.availability !== CalendarAvailability.closed)
              .reduce((acc, e) => acc + e.duration, 0);
            const freeDuration = computedEvents
              .filter((e) => e.availability === CalendarAvailability.free)
              .reduce((acc, e) => acc + e.duration, 0);
            const freePercentage = Math.floor((100 * freeDuration) / totallDuration);
            const resource = _storeData.resourcesData.find((r: any) => ps.resourceId.toUpperCase() === r.id.toUpperCase());
            const result = {
              resourceRequirementId: req.id,
              resourceRequirement: req,
              resourceId: ps.resourceId,
              resource: resource,
              events: computedEvents,
              reservedTo: resource.reservedTo,
              freeClass: 'todo',
              yours: resource.reservedTo === 'you',
              others: resource.reservedTo === 'other',
              freeColor: getFreeColor(freePercentage),
              freePercentage: freePercentage,
              schedule: ps,
            };
            return result;
          })
          .filter((x) => !!x.resource && x.resource.reservationsEnabled === true)
          .sort(sortFunction),
      }));

      const patchedGroupedRooms = groupedResources.map((gr, grindex) => ({
        ...gr,
        timelineSchedules: gr.timelineSchedules.map((ts, scheduleIndex) => ({
          ...ts,
          events: ts.events.map((e) => ({
            ...e,
            top:
              e.top +
              (_sizes.scheduleHeight + _sizes.scheduleMargin * 2) * scheduleIndex +
              groupedResources
                .filter((_, i) => i < grindex)
                .map((x) => x.height)
                .reduce((val, acc) => acc + val + (_sizes.groupDelimiterHeight - _sizes.scheduleMargin * 2), 0),
          })),
        })),
      }));

      const days: Day[] = daysBetweemDates(startOfDay(_filter.date), endOfDay(_filter.date)).map(
        (d) =>
          ({
            date: d,
            hours: [],
            sunday: false,
            today: false,
          }) as Day,
      );

      const temp24 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
      const startIsEnd = getDayOfYear(startOfDay(_filter.date)) === getDayOfYear(endOfDay(_filter.date));
      const hours24 = startIsEnd
        ? temp24.filter((h) => h >= getHours(startOfDay(_filter.date)) && h <= getHours(endOfDay(_filter.date)))
        : temp24;

      const startHours = hours24.filter((h) => h >= getHours(startOfDay(_filter.date)));
      const pim = hours24.filter((h) => h <= getHours(endOfDay(_filter.date)));
      const endHours = pim.filter((_, index) => index < pim.length - 1);
      days.forEach((day, index) => {
        //// Hours
        //const isStart = index === 0;
        //const isEnd = index === days.length - 1;
        //day.hours = isEnd ? endHours : isStart ? startHours : hours24;
        day.hours = hours24;
      });

      let finalPatchedGroupAndSortedRooms: any[] = [];

      _requirements.forEach((requirement) => {
        const found = patchedGroupedRooms.filter((x) => x.requirement.id === requirement.id);
        finalPatchedGroupAndSortedRooms.push(...found);
      });

      finalPatchedGroupAndSortedRooms = finalPatchedGroupAndSortedRooms.map((group) => ({
        ...group,
        timelineSchedules: group.timelineSchedules.filter((schedule) => {
          if (_filter.duration != null) {
            return schedule.events.some((e) => e.availability == '0');
          } else {
            return true;
          }
        }),
      }));

      const temp = days.map((day, index) => {
        const prevCount = days.filter((_it, iindex) => iindex < index).reduce((acc, it) => acc + it.hours.length, 0);
        return (prevCount + day.hours.length) * _sizes.quarterWidth * 4;
      });

      const backgroundString = temp.reduce((acc, it, index) => {
        return (
          acc +
          (index != 0 ? ',' : '') +
          ` linear-gradient(90deg,
      transparent ${it - 3}px,
      #233C5A ${it - 3}px,
      #233C5A ${it + 3}px,
      transparent ${it + 3}px)
      `
        );
      }, '');

      const emails = _filter.attendees.filter((x) => x.value).map((x) => x.value.toUpperCase());
      const nonUniqueLoadedAttendees = _loadedAttendees.loading
        ? []
        : _loadedAttendees.data.filter((x) => emails.includes(x.email.toUpperCase()));
      const distinctedAttendees = distinctBy(nonUniqueLoadedAttendees, (x) => x.email);

      return {
        attendees: distinctedAttendees.map((fA) => {
          const arr = fA.availabilityView.split('') as CalendarAvailability[];
          const reqAttendee = _filter.attendees.find((x) => x.value.toUpperCase() === fA.email.toUpperCase());
          return {
            name: reqAttendee.text,
            email: reqAttendee.value,
            optional: reqAttendee.optional,
            remotely: reqAttendee.remotely,
            availabilityView: fA.availabilityView,
            availability: getTotallAvailability(arr),
            availabilityViewArray: arr,
            items: fA.items.map((it) => {
              const scopedStart = startOfDay(_filter.date) > it.start ? startOfDay(_filter.date) : it.start;
              const scopedEnd = endOfDay(_filter.date) < it.end ? endOfDay(_filter.date) : it.end;
              const diffEndStart = Math.abs(differenceInMinutes(scopedStart, scopedEnd));
              const width = (diffEndStart / 15) * 20;
              return {
                ...it,
                width: width,
                left: Math.abs((differenceInMinutes(startOfDay(_filter.date), scopedStart) / 15) * 20),
              };
            }),
          };
        }),
        loading: _packedResourcesLoading || _packedSchedules.loading,
        filter: _filter,
        groupedRooms: finalPatchedGroupAndSortedRooms,
        requirements: _requirements,
        sort: _sortBy,
        sizes: _sizes,
        computed: {
          addition: Math.floor(getMinutes(startOfDay(_filter.date)) / 15) * _sizes.quarterWidth,
          background: backgroundString,
          totallHeight: patchedGroupedRooms.map((x) => x.height).reduce((curr, acc) => curr + acc, 0),
          totallWidth:
            days.reduce((acc, day) => acc + day.hours.length * _sizes.quarterWidth * 4, 0) +
            (getMinutes(endOfDay(_filter.date)) / 15) * _sizes.quarterWidth,
          days: days,
        },
      };
    },
  );
