import { InjectionToken, makeEnvironmentProviders } from '@angular/core';
import { ApiService } from './services/api.service';

export const API_BASEURL: InjectionToken<string> = new InjectionToken<string>('CUE_API_BASE_URL');
export function provideApi(options: { factory: () => string }) {
  return makeEnvironmentProviders([
    {
      provide: API_BASEURL,
      multi: false,
      useFactory: options.factory,
    },
    ApiService,
  ]);
}
