import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface CueSvgCacheInfo {
  domID: SvgIdentifier;
  viewBox: SVGViewBox;
}

type SvgIdentifier = string;
type SVGViewBox = string;

@Injectable({
  providedIn: 'root',
})
export class IconsCacheService {
  private document = inject(DOCUMENT);
  public readonly domParser = this.createDomParser(this.document);
  private loadingViewBox = `0 0 16 16`;

  private cacheSubjects: Record<SvgIdentifier, BehaviorSubject<CueSvgCacheInfo>> = {};

  private createDomParser(document: Document): (s: string) => HTMLElement {
    const e = document.createElement('div');
    return (s: string) => {
      e && (e.innerHTML = s);
      return e.firstChild as HTMLElement;
    };
  }

  private getSvgSubjectInternal(svgId: SvgIdentifier) {
    if (!this.cacheSubjects[svgId]) {
      this.cacheSubjects[svgId] = new BehaviorSubject<CueSvgCacheInfo>({
        viewBox: this.loadingViewBox,
        domID: this.getDOMID(svgId),
      });
    }
    return this.cacheSubjects[svgId];
  }

  public getSvgSubject(svgId: SvgIdentifier) {
    return this.getSvgSubjectInternal(svgId).asObservable();
  }

  private readonly svgDomCache: HTMLElement = (() => {
    const domCache = this.document.getElementById('cue-svg-cache') || this.domParser('<div id="cue-svg-cache"></div>');
    domCache.setAttribute(
      'style',
      `overflow: hidden;
      width: 0px;
      height: 0px;
      position: fixed;
      bottom: -2000px;
      contain: content;
      content-visibility: auto;
    `,
    );
    this.document.body.appendChild(domCache);
    return domCache;
  })();

  isCached(identifier: SvgIdentifier) {
    return this.cacheSubjects[identifier] != null;
  }

  getDOMID(identifier: SvgIdentifier) {
    return `cue_icon_cached_${identifier}`;
  }

  cache(identifier: SvgIdentifier, data: string): void {
    const svgElem = this.domParser(data);
    const viewBox = data.match(/viewBox="([^"]*)"/);
    svgElem?.setAttribute && svgElem.setAttribute('id', this.getDOMID(identifier));
    this.svgDomCache.appendChild(svgElem);
    this.getSvgSubjectInternal(identifier).next({
      domID: this.getDOMID(identifier),
      viewBox: viewBox?.[1] || this.loadingViewBox,
    });
  }
}
