import { createAction, emptyProps, props } from '@ngrx/store';
import { IReservationItem, OutlookReservationMode } from '@outlook-addin/cue-http';
import { IReservationDetail } from '@outlook-addin/cue-http';

export const loadReservationList = createAction('[Reservation] Load ReservationList', props<{ filter: any }>());

export const reservationListLoaded = createAction('[Reservation] ReservationList Loaded', props<{ reservationList: IReservationItem[] }>());

export const loadEventDetailFromGraph = createAction('[Reservation] Load EventDetail from Graph', props<{ itemId: string }>());

export const refreshReservationDetail = createAction('[Reservation] Refresh ReservationDetail', emptyProps);

// kdyz se nepovede nacist event z O365 - nejspise z duvodu jineho usera
export const loadCalendarEventFailed = createAction('[Reservation] Calendar Event failed');

export const loadReservationDetail = createAction('[Reservation] Load ReservationDetail', props<{ eventDetail: any }>());

export const reservationDetailLoadSuccess = createAction(
  '[Reservation] ReservationDetail Load success',
  props<{ detail: IReservationDetail }>(),
);

export const reservationDetailLoadFailed = createAction('[Reservation] ReservationDetail Load failed', props<{ error: string }>());

export const outlookReservationModeChange = createAction(
  '[Reservation] OutlookReservationMode Change',
  props<{ reservationMode: OutlookReservationMode }>(),
);
