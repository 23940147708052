<div class="safe-padding" style="text-align: center">
    @if ((outlookUserDifferentFromAddin$ | async) === true) {
        <h3>{{ translation.outlookAddin.differentOutlookAndAddinUser | transloco }}</h3>
    } @else {
        @if (userAuthorized) {
            <h2>{{ translation.outlookAddin.userIsLoggedIn | transloco }}</h2>
        }
        @if (!userAuthorized) {
            <h2>{{ translation.outlookAddin.loginUserInfo | transloco }}</h2>
        }
    }

    @if (!userAuthorized) {
        <button style="margin-top: 1em" (click)="login()">
            <cue-icon [icon]="cueLogin"></cue-icon>
            {{ translation.outlookAddin.login | transloco }}
        </button>
    }
</div>
