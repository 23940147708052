import { InjectionToken } from '@angular/core';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, createSelector, DefaultProjectorFn, MemoizedSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { RouterStateModel } from '../models/common.models';

export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state: AppState, action: Action) => {
    // if (action.type === PortalActions.signOutResult.type) {
    //     state = undefined;
    // }

    return reducer(state, action);
  };
}

const devOnlyMetaReducers: MetaReducer<AppState>[] = [];
const prodMetaReducers: MetaReducer<AppState>[] = [clearState];

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? prodMetaReducers
  : [...prodMetaReducers, ...devOnlyMetaReducers];

export interface AppState {
  router: RouterReducerState;
}

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<AppState, Action>>('Root reducers token', {
  factory: () => ({
    router: routerReducer,
  }),
});

// #region Router

export const getRouter = (state: AppState) => state.router;

export const getRouterState: MemoizedSelector<AppState, RouterStateModel, DefaultProjectorFn<RouterStateModel>> = createSelector(
  getRouter,
  (x) => (x != null ? (x.state as unknown as RouterStateModel) : null),
);

export const getRouterData = createSelector(getRouterState, (x: RouterStateModel) => (x != null ? x.data : null));
export const getRouterParams = createSelector(getRouterState, (x: RouterStateModel) => (x != null ? x.params : null));
export const getRouterQueryParams = createSelector(getRouterState, (x: RouterStateModel) => (x != null ? x.queryParams : null));

// #endregion
