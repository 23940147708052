import { Action, ActionReducerMap, combineReducers, createSelector } from '@ngrx/store';
import * as fromAccount from './account.reducers';
import * as fromReservation from './reservation.reducers';
import * as fromResources from './resources.reducers';
import * as fromUserInfo from './user-info.reducers';
import { AppState } from '../../shared/reducers';
import { HierarchyArea, Pack } from '@assist/shared/data';
import { IPropertyFilter, IReservationDetail } from '@outlook-addin/cue-http';
import { remove } from 'remove-accents';
import { LocalizedString } from '@cue/api';

export interface AppStateModel {
  accountData: fromAccount.State;
  reservationData: fromReservation.State;
  resourcesData: fromResources.State;
  userInfo: fromUserInfo.State;
}

export interface State extends AppState {
  app: AppStateModel;
}

const reducerMap: ActionReducerMap<AppStateModel, Action> = {
  accountData: fromAccount.reducer,
  reservationData: fromReservation.reducer,
  resourcesData: fromResources.reducer,
  userInfo: fromUserInfo.reducer,
};

const listToTree = (listBackup) => {
  const list = JSON.parse(JSON.stringify(listBackup));
  const map = {};
  let node = null;
  const roots: HierarchyArea[] = [];

  for (let i = 0; i < list.length; i += 1) {
    map[list[i].id] = i;
    list[i].children = [];
  }

  for (let i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentAreaId != null) {
      node.parentArea = list[map[node.parentAreaId]];
      list[map[node.parentAreaId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};

export function reducers(state: AppStateModel, action: Action) {
  return combineReducers(reducerMap)(state, action);
}

export const selectFeature = (state: State) => state.app;

export const selectAuthCode = createSelector(selectFeature, (state: AppStateModel) => state.accountData.authCode);

export const selectIsMsUserLoggedIn = createSelector(selectFeature, (state: AppStateModel) => state.accountData.msUserIsLoggedin);

export const selectReservationList = createSelector(selectFeature, (state: AppStateModel) => state.reservationData.reservationList);

export const selectReservationListIsLoading = createSelector(selectFeature, (state: AppStateModel) => state.reservationData.isLoading);

export const selectOutlookReservationMode = createSelector(
  selectFeature,
  (state: AppStateModel) => state.reservationData.reservationOutlookMode,
);

export const selectReservationDetail = createSelector<State, AppStateModel, Pack<IReservationDetail>>(selectFeature, (state) =>
  state.reservationData.reservationError
    ? {
        data: null,
        error: state.reservationData.reservationError,
      }
    : {
        data: state.reservationData.reservationDetail,
        error: null,
      },
);

export const selectResourcesFilter = createSelector(selectFeature, (state: AppStateModel) => state.resourcesData.resourcesFilter);

export const selectPackedUserInfo = createSelector(selectFeature, (state: AppStateModel) => state.userInfo.user);

export const selectOutlookUserDifferentFromAddin = createSelector(
  selectFeature,
  (state: AppStateModel) => state.userInfo.isDifferentUserInOutlookAndAddin,
);

export const selectAreas = createSelector(selectFeature, (state: AppStateModel) => state.resourcesData.areas);

export const selectHierarchyAreas = createSelector(selectFeature, (state: AppStateModel) => {
  const map = listToTree(state.resourcesData.areas);
  return map;
});

export const selectCurrentResourceListFilter = createSelector(
  selectFeature,
  (state: AppStateModel) => state.resourcesData.currentResourceListFilter,
);

export const requirements = createSelector(selectFeature, selectCurrentResourceListFilter, (state: AppStateModel, oldOne) => {
  let reqs: {
    id: string;
    resourceTypeId: number;
    areaIds: number[];
    capacity: [number, number];
    filters: IPropertyFilter[];
    resourcePropertyName: LocalizedString;
  }[] = [];

  if (state.resourcesData.requirements.length > 0) {
    reqs = state.resourcesData.requirements.map((req) => {
      const ret = {
        id: req.id,
        resourceTypeId: req.resourceTypeId,
        areaIds: req.areaIds,
        capacity: req.capacity,
        resourcePropertyName: state.resourcesData.resourcesFilter.find((rF) => rF.resourceTypeId == req.resourceTypeId).resourceTypeName,
        filters: [],
      };

      if (state.resourcesData.currentResourceListFilter) {
        ret.filters = state.resourcesData.currentResourceListFilter.additionalFilterList.filter((f) => f.requirementId === req.id);
      }
      return ret;
    });
  } else {
    reqs = state.resourcesData.virtualRequirements.map((vReq) => ({
      id: vReq.id,
      resourceTypeId: vReq.filter.resourceTypeId,
      resourcePropertyName: vReq.filter.resourceTypeName,
      areaIds: state.resourcesData.areas.map((a) => a.id),
      capacity: [vReq.filter.capacityMin, vReq.filter.capacityMax],
      filters: [],
    }));
  }

  // TODO - resit kdyz nejsou zadny
  return {
    start: state.resourcesData.currentResourceListFilter?.start,
    end: state.resourcesData.currentResourceListFilter?.end,
    oldOne,
    reqs,
  };
});
// Todo reuse!
export const realRequirements = createSelector(selectFeature, selectCurrentResourceListFilter, (state: AppStateModel) => {
  return state.resourcesData.requirements.map((req) => {
    const ret = {
      id: req.id,
      resourceTypeId: req.resourceTypeId,
      areaIds: req.areaIds,
      capacity: req.capacity,
      resourcePropertyName: state.resourcesData.resourcesFilter.find((rF) => rF.resourceTypeId == req.resourceTypeId).resourceTypeName,
      filters: [],
    };

    if (state.resourcesData.currentResourceListFilter) {
      ret.filters = state.resourcesData.currentResourceListFilter.additionalFilterList.filter((f) => f.requirementId === req.id);
    }
    return ret;
  });
});

export const selectGroupedResourceList = createSelector(selectFeature, requirements, (state: AppStateModel, x) => {
  const data = !state.resourcesData.resourceList.loading
    ? x.reqs.map((req) => {
        const ret = {
          id: req.id,
          resourceTypeId: req.resourceTypeId,
          resourcePropertyName: req.resourcePropertyName,
          resources: state.resourcesData.resourceList.data?.filter((r) => {
            const filterNotEmpty =
              state.resourcesData.currentResourceListFilter?.resourceName != null &&
              state.resourcesData.currentResourceListFilter?.resourceName != '';
            const filterMatch1 = filterNotEmpty
              ? remove(r.resourceDisplayName)
                  .toLowerCase()
                  .includes(remove(state.resourcesData.currentResourceListFilter?.resourceName ?? '').toLowerCase())
              : true;

            const filterMatch2 = filterNotEmpty
              ? r.resourceDisplayNameForApp
                  ?.toLowerCase()
                  .includes(state.resourcesData.currentResourceListFilter?.resourceName.toLowerCase())
              : true;
            const onlyAvailable = state.resourcesData.currentResourceListFilter?.showOnlyAvailable;
            return (!onlyAvailable || r.isAvailable) && (filterMatch1 || filterMatch2) && r.requirementIds.includes(req.id);
          }),
        };

        return ret;
      })
    : [];

  return {
    loading: state.resourcesData.resourceList.loading,
    data: data,
    error: state.resourcesData.resourceList.error,
  };
});

export const selectResourceList = createSelector(selectFeature, (state: AppStateModel) => state.resourcesData.resourceList);

export const selectResourceDetail = createSelector(selectFeature, (state: AppStateModel) => state.resourcesData.resourceDetail);

export const selectEventDetail = createSelector(selectFeature, (state: AppStateModel) => state.reservationData.eventDetail);

export const selectRequirementsCount = createSelector(selectFeature, (state: AppStateModel) => state.resourcesData.requirements.length);
