import { Component, Input } from '@angular/core';
import { AddinConfigService, ResourceStatus } from '@outlook-addin/shared';
import { translation } from '@assist/shared/translations';
import { ResourceTimelineStateToColorPipe } from '../shared/pipes';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'addin-resource-availability',
  templateUrl: './resource-availability.component.html',
  styleUrls: ['./resource-availability.component.scss'],
  standalone: true,
  imports: [ResourceTimelineStateToColorPipe, DatePipe, TranslocoModule, CommonModule],
})
export class ResourceAvailabilityComponent {
  protected readonly translation = translation;

  statusList: ResourceStatus[];
  timelineWindowList: string[] = [];

  @Input() isRestrictedResource: boolean;
  @Input() startDate: Date;
  @Input() endDate: Date;

  @Input() set timeline(value: string) {
    if (value) {
      this.timelineWindowList = value.split('');
    }
  }
  constructor(private configService: AddinConfigService) {
    this.statusList = this.configService.value.configuration.statusesList;
  }

  getStatusName(value: string) {
    return this.statusList?.filter((x) => x.status === +value)[0];
  }

  isSameDate() {
    if (this.startDate && this.endDate) {
      return (
        this.startDate.getDay() === this.endDate.getDay() &&
        this.startDate.getMonth() === this.endDate.getMonth() &&
        this.startDate.getFullYear() === this.endDate.getFullYear()
      );
    }
    return false;
  }
}
