import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class MicrosoftLoginGuard implements CanActivate {
  constructor() {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authCode = route.queryParams['code'];

    console.log('MicrosoftLoginGuard');
    console.log(authCode);

    return true;
    // return this.authService.getTokenFromServer(authCode).pipe(
    //   tap((r) => {
    //     if (r?.success) {
    //       this.authService.saveApiToken(r.data!.token);
    //       this.authService.saveApiRefreshToken(r.data!.refreshToken);
    //     } else {
    //       this.authService.removeApiToken();
    //       this.authService.removeApiRefreshToken();
    //     }
    //   }),
    //   map((r) => {
    //     if (r.success) {
    //       const redirectUrl = sessionStorage.getItem('redirect_url')!;
    //       sessionStorage.removeItem('redirect_url');
    //       return this.router.parseUrl(
    //         redirectUrl !== undefined ? redirectUrl : '/dashboard'
    //       );
    //     } else {
    //       return this.router.parseUrl('/account/login');
    //     }
    //   })
    // );
  }
}
