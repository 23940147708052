import { Pipe, PipeTransform } from '@angular/core';
import { cueBlock, cueGroup, cueIcon, cueUser, cueArrowDown } from '@cue/ui/icons';
import { ResourceAssignType } from '@outlook-addin/cue-http';
import { translation } from '@assist/shared/translations';

@Pipe({
  name: 'assignToTranslateKey',
  standalone: true,
})
export class AssignToTranslateKeyToPipe implements PipeTransform {
  transform(assignTo: ResourceAssignType): string {
    switch (assignTo) {
      case ResourceAssignType.Mine:
        return translation.outlookAddin.assignedMine;
      case ResourceAssignType.Ours:
        return translation.outlookAddin.assignedOurs;
      case ResourceAssignType.Restricted:
        return translation.outlookAddin.assignedRestricted;
      case ResourceAssignType.Free:
        return translation.outlookAddin.assignedFree;
      default:
        return '';
    }
  }
}
