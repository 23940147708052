import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomUnsafeSanitizerDoNotUseMeService } from '../services/custom-unsafe-sanitizer-do-not-use-me.service';

@Pipe({
  name: 'cueCustomUnsafeSanitize',
  standalone: true,
  pure: true,
})
export class CustomUnsafeSanitizePipe implements PipeTransform {
  private readonly sanitizer = inject(DomSanitizer);
  private readonly customSanitizer = inject(CustomUnsafeSanitizerDoNotUseMeService);

  transform(value: string | number): unknown {
    if (value === null || value === undefined || typeof value !== 'string') {
      return value;
    }
    return this.sanitizer.bypassSecurityTrustHtml(this.customSanitizer.SanitizeHtml(value));
  }
}
