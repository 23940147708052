<button [routerLink]="'../'">back to main page</button>
<h1>APPOINTMENT EDIT</h1>
<ng-container>
    <div [formGroup]="form">
        <div class="date-container">
            BODY:
            <input formControlName="emailBody" /><br />
            @if (form.get('emailBody').touched && form.get('emailBody').hasError('required')) {
                <span class="error">Vyplňte prosím</span>
            }
        </div>
        <button (click)="saveChanges()">Save</button>
    </div>
</ng-container>
