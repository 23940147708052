import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  forwardRef,
  Optional,
  Host,
  SkipSelf,
  Injector,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from '@angular/core';

import { CheckBoxModule } from '@progress/kendo-angular-inputs';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { FloatingLabelComponent } from '@progress/kendo-angular-label';
import { cueClose, IconComponent } from '@cue/ui/icons';

@Component({
  selector: 'assist-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [ReactiveFormsModule, CheckBoxModule, IconComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() allowTripple: boolean;
  @Output() change = new EventEmitter<any>();
  @Input() checked: boolean;
  /**
   * Classes applied to the label element
   */
  @Input() labelClass: string;
  @Input() title = '';
  _control: AbstractControl;
  cueClose = cueClose;
  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() public floatingLabel: FloatingLabelComponent,
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl?.control) {
      this._control = ngControl.control as FormControl<boolean>;
    } else {
      if (this.controlContainer) {
        this._control = (this.controlContainer.control as any).get(ngControl.name);
      }
    }

    if (this._control && this.allowTripple === true) {
      if (this.floatingLabel) {
        this.floatingLabel.focused = true;
      }
    }
  }

  focus() {
    this.cdr.markForCheck();
    if (this.floatingLabel) {
      this.floatingLabel.focused = true;
    }
  }

  blur() {
    this.cdr.markForCheck();
    if (this.floatingLabel) {
      this.floatingLabel.focused = false;
    }
  }

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    this.cdr.markForCheck();
  }

  handleChange(e: Event) {
    this.change.emit((e.target as HTMLInputElement).checked);
    e.preventDefault();
    e.stopPropagation();
  }
}
