import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMapTo } from 'rxjs/operators';
import * as UserActions from '../actions/user.actions';
import { forkJoin } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { CalendarsService } from '@cue/calendars';

@Injectable()
export class UserEffects {
  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUser),
      switchMapTo(
        forkJoin([
          this.calendarsService.getMeInfo(),
          this.calendarsService.getImageUrl('me').pipe(map((url) => this.sanitizer.bypassSecurityTrustUrl(url))),
        ]),
      ),
      map(([user, safeurl]) => UserActions.userLoaded({ user: { ...user, base64Image: safeurl } })),
    ),
  );

  constructor(
    private sanitizer: DomSanitizer,
    private actions$: Actions,
    private calendarsService: CalendarsService,
  ) {}
}
