import { Component } from '@angular/core';
import { IAreaDetail, IPropertyFilter, IReservationItem, IResourceFilterUpdateCrate, STORAGE_KEYS } from '@outlook-addin/cue-http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromModule from '../ngrx/reducers';

import { ReservationActions, ResourcesActions } from '../ngrx/actions';
import { cueClose, cueFilter, cueOrders, IconComponent } from '@cue/ui/icons';
import { translation } from '@assist/shared/translations';
import MessageRead = Office.MessageRead;
import AppointmentRead = Office.AppointmentRead;
import AppointmentCompose = Office.AppointmentCompose;
import { HierarchyArea, Pack } from '@assist/shared/data';
import { loadResourceList } from '../ngrx/actions/resources.actions';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { ResourceFilterComponent } from '../resource-filter/resource-filter.component';
import { ResourceListComponent } from '../resource-list/resource-list.component';

@Component({
  selector: 'addin-reservation-list',
  templateUrl: './reservation-list.component.html',
  styleUrls: ['./reservation-list.component.scss'],
  standalone: true,
  imports: [IconComponent, CommonModule, TranslocoModule, ResourceFilterComponent, ResourceListComponent],
})
export class ReservationListComponent {
  translation = translation;
  showFilter: boolean;
  resourcesFilter$: Observable<IPropertyFilter[]>;
  resourceList$: Observable<Pack<any[]>>;
  groupedResourceList$: Observable<Pack<any[]>>;
  currentResourceFilter$: Observable<IResourceFilterUpdateCrate>;

  reqCount$: Observable<number>;
  isLoading$: Observable<boolean>;

  data: any;
  authCode$: Observable<string>;
  reservationListItem$: Observable<IReservationItem[]>;
  areas$: Observable<IAreaDetail[]>;
  hierarchyAreas$: Observable<HierarchyArea[]>;
  constructor(private store: Store<fromModule.State>) {
    this.store.dispatch(ResourcesActions.loadAreas());
    this.store.dispatch(ResourcesActions.loadResourcesFilter());

    this.resourcesFilter$ = this.store.select(fromModule.selectResourcesFilter);
    this.resourceList$ = this.store.select(fromModule.selectResourceList);
    this.groupedResourceList$ = this.store.select(fromModule.selectGroupedResourceList);
    this.hierarchyAreas$ = this.store.select(fromModule.selectHierarchyAreas);
    this.areas$ = this.store.select(fromModule.selectAreas);
    this.currentResourceFilter$ = this.store.select(fromModule.selectCurrentResourceListFilter);
    this.reqCount$ = this.store.select(fromModule.selectRequirementsCount);

    this.store.dispatch(ReservationActions.loadReservationList({ filter: null }));
    this.reservationListItem$ = this.store.select(fromModule.selectReservationList);
    this.authCode$ = this.store.select(fromModule.selectAuthCode);
    this.isLoading$ = this.store.select(fromModule.selectReservationListIsLoading);

    this.data = localStorage.getItem(STORAGE_KEYS.accessTokenMicrosoft);

    Office.onReady().then((x) => {
      // this.performOutlookAction();
    });
  }

  private performOutlookAction() {
    const itemType = Office.context.mailbox.item.itemType as Office.MailboxEnums.ItemType;
    if (itemType == Office.MailboxEnums.ItemType.Message) {
      const itemData = Office.context.mailbox.item as MessageRead;
      console.log(itemData);
      const itemDataEdit2 = Office.context.mailbox.item as AppointmentCompose;
      itemDataEdit2.getItemIdAsync((x) => {
        this.store.dispatch(ReservationActions.loadEventDetailFromGraph({ itemId: x.value }));
      });
    } else if (itemType == Office.MailboxEnums.ItemType.Appointment) {
      const itemData = Office.context.mailbox.item as AppointmentRead;
      if (itemData.itemId) {
        this.store.dispatch(
          ReservationActions.loadEventDetailFromGraph({
            itemId: Office.context.mailbox.convertToRestId(itemData.itemId, 'v2.0'),
          }),
        );
      } else {
        const itemDataEdit = Office.context.mailbox.item as AppointmentCompose;
        itemDataEdit.getItemIdAsync((x) => {
          if (!x.error) {
            this.store.dispatch(
              ReservationActions.loadEventDetailFromGraph({
                itemId: Office.context.mailbox.convertToRestId(x.value, 'v2.0'),
              }),
            );
          }
        });
      }
    }
  }

  resetAllFilters() {
    // this.showFilter = false;
    this.store.dispatch(ResourcesActions.resetRequirements());
  }

  protected readonly cueFilter = cueFilter;
  protected readonly cueClose = cueClose;

  onRefresh() {
    this.store.dispatch(loadResourceList());
  }
}
