<ng-content></ng-content>
@if (valueNormalizer) {
    <kendo-multiselect
        (focusin)="focus()"
        (focusout)="blur()"
        [valuePrimitive]="valuePrimitive"
        [kendoDropDownFilter]="dropdownFilter"
        [formControl]="$any(_control)"
        [data]="data"
        [textField]="textField"
        [filterable]="filterable"
        [valueField]="valueField"
        [popupSettings]="popupSettings"
        [placeholder]="placeholder"
        [valueNormalizer]="valueNormalizer"
        (open)="open.emit($event)"
        [value]="value"
        (valueChange)="valueChange.emit($event)"
        (filterChange)="filterChange.emit($event)"
        [allowCustom]="allowCustom"
    >
        @if (itemTemplate) {
            <ng-template kendoMultiSelectItemTemplate let-item>
                <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
            </ng-template>
        }

        @if (tagTemplate) {
            <ng-template kendoMultiSelectTagTemplate let-item>
                <ng-container [ngTemplateOutlet]="tagTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
            </ng-template>
        }

        @if (noDataTemplate) {
            <ng-template kendoMultiSelectNoDataTemplate>
                <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
            </ng-template>
        }
    </kendo-multiselect>
}
