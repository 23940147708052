<button [routerLink]="'../'">back to main page</button>
<ng-container #userAuthorized>
    <div [formGroup]="form">
        <div class="date-container">
            from:
            <input type="datetime-local" formControlName="startDate" /><br />
            @if (form.get('startDate').touched && form.get('startDate').hasError('required')) {
                <span class="error">Vyplňte prosím datum OD</span>
            }
            to:
            <input type="datetime-local" formControlName="endDate" /><br />
            @if (form.get('endDate').touched && form.get('endDate').hasError('required')) {
                <span class="error">Vyplňte prosím datum DO</span>
            }
        </div>

        <div class="colleagueList" formArrayName="colleagueEmailList">
            <div>COLLEAGUES</div>
            <button type="button" (click)="addUser()">+</button>
            @for (colleague of colleagueEmailList.controls; track colleague; let i = $index) {
                <div>
                    <label for="colleague-{{ i }}">Email:</label>
                    <input id="colleague-{{ i }}" type="text" [formControlName]="i" />
                    <button type="button" (click)="deleteUser(i)">x</button>
                </div>
            }
        </div>
        <button (click)="findMeetingTime()">find Meeting times</button>
    </div>

    @if (meetingSuggestions$ | async; as meetingSuggestions) {
        @if (meetingSuggestions.meetingTimeSuggestions?.length > 0) {
            <h1>MEETING TIMES:</h1>
            @for (suggestion of meetingSuggestions.meetingTimeSuggestions; track suggestion) {
                <div class="reason">
                    Od: {{ suggestion.meetingTimeSlot.start.dateTime | date: 'short' }} Do:
                    {{ suggestion.meetingTimeSlot.end.dateTime | date: 'short' }}<br />
                    reason: {{ suggestion.suggestionReason }}<br />
                    confidence: {{ suggestion.confidence }}%<br />
                </div>
            }
        } @else {
            <h1>NO MEETING TIMES</h1>
            @if (meetingSuggestions?.emptySuggestionsReason) {
                <div>Reason: {{ meetingSuggestions?.emptySuggestionsReason }}</div>
            }
        }
    }
</ng-container>
