import { Component } from '@angular/core';
import { translation } from '@assist/shared/translations';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'addin-reservation-cannot-fetch',
  templateUrl: './reservation-cannot-fetch.component.html',
  styleUrls: ['./reservation-cannot-fetch.component.scss'],
  standalone: true,
  imports: [TranslocoModule],
})
export class ReservationCannotFetchComponent {
  translation = translation;
}
