function concatIfExistsPath(path: string, suffix: string): string {
  return path ? `${path}.${suffix}` : suffix;
}

export function setPathsToObjectValues<T extends object | string>(suffix: string, objectToTransformOrEndOfPath: T, path = ''): T {
  return typeof objectToTransformOrEndOfPath === 'object'
    ? Object.entries(objectToTransformOrEndOfPath).reduce((objectToTransform, [key, value]) => {
        objectToTransform[key] = setPathsToObjectValues(key, value, concatIfExistsPath(path, suffix));

        return objectToTransform;
      }, {} as T)
    : (concatIfExistsPath(path, suffix) as T);
}

function AnyClass<Props>(): new () => Props {
  return class {} as any;
}

export function getLocalization<T = any>(translations: T): T {
  const temp = new (AnyClass<T>())();
  Object.assign(temp, setPathsToObjectValues('', translations as unknown as object));
  return temp;
}
