import { translation } from '@assist/shared/translations';

export const EVENT_STATUSES = [
  {
    text: translation.common.cancelled,
    value: {
      canceled: true,
      accepted: null,
      color: '#ce2828',
      class: 'cancelled',
    },
  },
  {
    text: translation.common.accepted,
    value: {
      canceled: false,
      accepted: true,
      color: '#33b630',
      class: 'accepted',
    },
  },
  {
    text: translation.common.tentative,
    value: {
      canceled: false,
      accepted: null,
      color: '#ea8f08',
      class: 'tentative',
    },
  },
  {
    text: translation.common.rejected,
    value: {
      canceled: false,
      accepted: false,
      color: '#ce2828',
      class: 'rejected',
    },
  },
];
