<label style="cursor: pointer" [class]="labelClass">
    @if (_control) {
        <input
            (change)="handleChange($event)"
            [checked]="checked"
            [formControl]="$any(_control)"
            [indeterminate]="allowTripple === true && _control.value === null"
            kendoCheckBox
            type="checkbox"
        />
        @if (allowTripple === true && _control.value != null) {
            <cue-icon (click)="_control?.setValue(null); $event.preventDefault(); $event.stopPropagation()" [icon]="cueClose"></cue-icon>
        }
    }

    @if (!_control) {
        <input (change)="handleChange($event)" [checked]="checked" kendoCheckBox type="checkbox" />
    }
    <span>
        {{ title }}
    </span>
</label>
