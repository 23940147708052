import { CurrentUser, PackedCurrentUser } from '../models';
import { createReducer, on, Action } from '@ngrx/store';
import { clearCurrentUser, currentUserLoaded, loadCurrentUser } from '../actions/current-user.actions';

export const initialState: PackedCurrentUser = {
  error: null,
  loading: true,
  data: null,
};

const reducer = createReducer(
  initialState,
  on(loadCurrentUser, (state) => ({
    loading: true,
    data: null,
    error: null,
  })),
  on(currentUserLoaded, (state, action) => action.user),
  on(clearCurrentUser, (state) => ({
    ...state,
    data: null,
  })),
);

export function currentUserReducer(state: PackedCurrentUser, action: Action) {
  return reducer(state, action);
}
