import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[cueClasserizeParent]',
  standalone: true,
})
export class ClasserizeParentDirective implements AfterViewInit {
  @Input() parentClass = 'classerizedParentClass';
  @Input() parentLevel = 1;
  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    let parentPointer: HTMLElement | null = this.elementRef.nativeElement;
    for (let i = 0; i < this.parentLevel; i++) {
      parentPointer = parentPointer.parentElement;
      if (parentPointer == null) {
        break;
      }
    }
    if (parentPointer) {
      parentPointer.classList.add(this.parentClass);
    }
  }
}
