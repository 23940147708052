import { TimelineSettings } from '../models';
import { createReducer, on, Action, State } from '@ngrx/store';
import * as TimelineSettingsActions from '../actions/timeline.actions';

export const initialState: TimelineSettings = {
  sizes: {
    scheduleHeight: 28,
    quarterWidth: 20,
    scheduleMargin: 3,
    delimiterWidth: 1,
    hourHeight: 60,
    dateHeight: 30,
    groupDelimiterHeight: 38,
  },
  mode: 'timeline',
  sortBy: 'name asc',
};

const reducer = createReducer(
  initialState,
  on(TimelineSettingsActions.setQuarterWidth, (_state, { width }) => ({
    ..._state,
    sizes: {
      ..._state.sizes,
      quarterWidth: width,
    },
  })),
  on(TimelineSettingsActions.setTimelineSettings, (_state, { timelineSettings }) => timelineSettings),
  on(TimelineSettingsActions.setTimelineMode, (_state, { mode }) =>
    mode !== _state.mode
      ? {
          ..._state,
          mode: mode,
        }
      : _state,
  ),
  on(TimelineSettingsActions.setTimelineHeight, (_state, { height }) => ({
    ..._state,
    sizes: {
      ..._state.sizes,
      scheduleHeight: height,
    },
  })),
  on(TimelineSettingsActions.setTimelineSort, (_state, { sort }) => ({
    ..._state,
    sortBy: sort,
  })),
);

export function timelineReducer(state: TimelineSettings, action: Action) {
  return reducer(state, action);
}
