import { LocalizedString } from '@cue/api';

export enum ResourceAssignType {
  Free = 'free',
  Mine = 'mine',
  Ours = 'ours',
  Restricted = 'restricted',
}

export interface IResourceListItem {
  requirementIds: string[];
  resourceId: number;
  resourceGuid: string;
  resourceDisplayName: string;
  resourceDisplayNameForApp: string;
  resourceUserName: string;
  locations?: IAddinLocationDetail[];
  isAvailable: boolean;
  //isAssignToYou: boolean;
  isAssignedTo: ResourceAssignType;
  // status: IAddinResourceStatus[];
  timeLine: string;
  resourceDetailAssistUrl: string;
}

export interface IAddinLocationDetail {
  displayName: string;
  locationUri: string;
}

export interface IAddinResourceStatus {
  status: number;
  name: string;
  color: string;
}

export interface Requirement {
  id: string;
  resourceTypeId: number;
  capacity: [number, number];
  areaIds: number[];
}

export interface IResourceFilterUpdateCrate {
  start?: Date;
  end?: Date;
  showOnlyAvailable?: boolean;
  resourceName?: string;
  additionalFilterList: IAdditionalFilterItem[];
}

export interface IAdditionalFilterItem {
  id: string;
  requirementId: string;
  dataTypeId: number;
  valueNumberMin: number;
  valueNumberMax: number;
  valueDecimalMin: number;
  valueDecimalMax: number;
  valueBoolean: boolean;
  valueString: string;
  capacityMin: number;
  capacityMax: number;
  valueChoiceId: string[];
}

export interface IAreaDetail {
  id: number;
  parentAreaId?: number;
  name: string;
}

export interface IPropertyFilter {
  resourceTypeId: number;
  resourceTypeName: LocalizedString;
  addinPropertyDetails: IAddinPropertyDetail[];
  capacityMin: number;
  capacityMax: number;
  capacityVisible: boolean;
  resourceCount: number;
  rank: number;
}

export interface IAddinPropertyDetail {
  id: string;
  name: LocalizedString;
  dataTypeId: number;
  isFilterable: boolean;
  defaultValueNumber: number;
  defaultValueDecimal: number;
  defaultValueBoolean: boolean;
  defaultValueString: string;
  valueNumberMax: number;
  valueNumberMin: number;
  valueDecimalMax: number;
  valueDecimalMin: number;
  choiceIds: ChoiseItem[];
}

export interface ChoiseItem {
  id: string;
  imageUrl: string;
  name: string;
}
