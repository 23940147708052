import { Injectable } from '@angular/core';
import { CSCode, ServerType } from '@cue/api';

@Injectable({
  providedIn: 'root',
})
export class LicensingService {
  private csCodeIsResourceStandardLike(csCode: string) {
    return csCode === CSCode.AssistWorkplace || csCode === CSCode.AssistCustom || csCode === CSCode.ResourceStandard;
  }

  // Helper methods

  // Only for our server
  isBasicResourceLike(hasCsCode: { csCode: CSCode }) {
    return hasCsCode.csCode === CSCode.ResourceBasic || this.isStandardResourceLike(hasCsCode);
  }

  isStandardResourceLike(hasCsCode: { csCode: CSCode }) {
    return this.csCodeIsResourceStandardLike(hasCsCode.csCode) || this.isPremiumResourceLike(hasCsCode);
  }

  isPremiumResourceLike(hasCsCode: { csCode: CSCode }) {
    return hasCsCode.csCode === CSCode.ResourcePremium;
  }

  // Main methods
  availableForServerType(hasCsCode: { csCode: CSCode }, mode: ServerType) {
    // TOUCH-3932
    // if (mode === "touchone-calendar") {
    //   return this.isBasicResourceLike(hasCsCode);
    // }
    return this.isStandardResourceLike(hasCsCode);
  }
}
