// db.ts
import Dexie, { Table } from 'dexie';

export interface ConfigurationCache {
  data: string;
  timestamp: string;
  version: number;
  id: number;
}

export interface ResourcesCache {
  id: number;
  data: any;
  timestamp: string;
  username: string;
  version: number;
}

export interface AssistLog {
  error: {
    message: string;
    stack: string;
  };
  date: Date;
  id: number;
}

export type NewsTitleData =
  | {
      body: string;
    }
  | false;

export interface NewsTitleCache {
  id: number;
  data: NewsTitleData;
  timestamp: string;
}

export class AssistDB extends Dexie {
  configurations!: Table<ConfigurationCache, number>;
  resources!: Table<ResourcesCache, number>;
  news!: Table<NewsTitleCache, number>;
  logs!: Table<AssistLog, number>;
  events!: Table<any, number>;
  constructor() {
    super('assist');
    this.version(3).stores({
      configurations: '++id',
      resources: '++id',
      news: '++id',
      logs: '++id',
      events: '++id',
    });
  }
}

export const assistDB = new AssistDB();
