import { createReducer, on, Action } from '@ngrx/store';
import * as ResourcesActions from '../actions/resources.actions';
import { AssistResourceBM } from '@cue/api';
import { Pack } from '../models';

export const initialState: Pack<AssistResourceBM[]> = {
  loading: false,
  data: [],
};

const reducer = createReducer(
  initialState,
  on(ResourcesActions.onlyLoadResources, (state) => ({
    ...state,
    loading: true,
  })),
  on(ResourcesActions.onlyResourcesLoaded, (state, { resources }) => ({
    ...state,
    loading: false,
    data: resources,
  })),
  on(ResourcesActions.loadResources, (state) => ({
    ...state,
    loading: true,
  })),
  on(ResourcesActions.resourcesLoaded, (state, { resources }) => ({
    ...state,
    loading: false,
    data: resources,
  })),
);

export function resourcesReducer(state: Pack<AssistResourceBM[]>, action: Action) {
  return reducer(state, action);
}
