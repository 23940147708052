import { AfterViewInit, Component, ElementRef, HostBinding, inject, Input } from '@angular/core';
import { cueIcon } from '../../library/icons';
import { BehaviorSubject, filter, Subscription, switchMap, tap } from 'rxjs';
import { IconsCacheService } from '../../services/icons-cache.service';

@Component({
  selector: 'cue-icon',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16">
      <use></use>
    </svg>
  `,
  styleUrls: ['./icon.component.scss'],
  standalone: true,
})
export class IconComponent implements AfterViewInit {
  @Input() size: 'normal' | 'small' = 'normal';
  @Input() customIconClass: string | null = null;

  private _sub?: Subscription;
  private _iconSubject = new BehaviorSubject(undefined as cueIcon | undefined);
  private readonly elRef = inject(ElementRef);
  private readonly iconsCacheService = inject(IconsCacheService);

  @Input() set icon(value: cueIcon) {
    this._iconSubject.next(value);
  }

  @Input() theme: 'primary-500' | 'error-500' | 'neutral' | 'neutral-inverse' = 'primary-500';

  @HostBinding('class')
  get elementClasses() {
    return {
      'transition-all': true,
      'ease-in-out': true,
      'duration-100': true,
      // 'fill-neutral-100': this.customIconClass == null && this.theme !== "primary",
      'fill-primary-500': this.customIconClass == null && this.theme === 'primary-500',
      'fill-neutral-800': this.customIconClass == null && this.theme === 'neutral',
      'dark:fill-neutral-100': this.customIconClass == null && this.theme === 'neutral',
      'fill-neutral-100': this.customIconClass == null && this.theme === 'neutral-inverse',
      'dark:fill-neutral-800': this.customIconClass == null && this.theme === 'neutral-inverse',
      'fill-error-500': this.customIconClass == null && this.theme === 'error-500',
      'scale-50': this.size == 'small',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      [this.customIconClass!]: this.customIconClass != null,
    };
  }

  ngAfterViewInit() {
    const element = this.elRef.nativeElement;
    const svg = element.querySelector('svg') as SVGElement;

    this._sub = this._iconSubject
      .pipe(
        filter((value) => value !== undefined),
        tap((value) => {
          if (!this.iconsCacheService.isCached(value!.name)) {
            this.iconsCacheService.cache(value!.name, value!.data);
          }
        }),
        switchMap((value) => this.iconsCacheService.getSvgSubject(value!.name)),
      )
      .subscribe((svgCache) => {
        svg.children[0].setAttribute('href', '#' + svgCache.domID);
        svg.setAttribute('viewBox', svgCache.viewBox);
      });
  }
}
