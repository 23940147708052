import { Component, NgZone } from '@angular/core';
import { ReservationActions } from '../ngrx/actions';
import { Store } from '@ngrx/store';
import * as fromModule from '../ngrx/reducers';
import { Router } from '@angular/router';
import MessageRead = Office.MessageRead;
import AppointmentCompose = Office.AppointmentCompose;
import AppointmentRead = Office.AppointmentRead;
import { LoadingComponent } from '@cue/loading';

@Component({
  selector: 'addin-main-start-detail',
  templateUrl: './main-start.component.html',
  styleUrls: ['./main-start.component.scss'],
  standalone: true,
  imports: [LoadingComponent],
})
export class MainStartComponent {
  constructor(
    private store: Store<fromModule.State>,
    private router: Router,
    private zone: NgZone,
  ) {
    Office.onReady().then((x) => {
      this.performOutlookAction();
    });
  }

  private performOutlookAction() {
    const locationSearch = window.location.search;

    const itemType = Office.context.mailbox.item.itemType as Office.MailboxEnums.ItemType;
    if (itemType == Office.MailboxEnums.ItemType.Message) {
      const itemData = Office.context.mailbox.item as MessageRead;
      const itemDataEdit2 = Office.context.mailbox.item as AppointmentCompose;
      itemDataEdit2.getItemIdAsync((x) => {
        this.store.dispatch(ReservationActions.loadEventDetailFromGraph({ itemId: x.value }));
      });
    } else if (itemType == Office.MailboxEnums.ItemType.Appointment) {
      const itemData = Office.context.mailbox.item as AppointmentRead;
      if (itemData.itemId) {
        this.store.dispatch(
          ReservationActions.loadEventDetailFromGraph({
            itemId: Office.context.mailbox.convertToRestId(itemData.itemId, 'v2.0'),
          }),
        );
      } else {
        const itemDataEdit = Office.context.mailbox.item as AppointmentCompose;
        itemDataEdit.getItemIdAsync((x) => {
          if (!x.error) {
            this.store.dispatch(
              ReservationActions.loadEventDetailFromGraph({
                itemId: Office.context.mailbox.convertToRestId(x.value, 'v2.0'),
              }),
            );
          } else {
            this.zone.run(() => {
              this.router.navigate(['reservations']);
            });
          }
        });
      }
    }
  }
}
