import { Pipe, PipeTransform } from '@angular/core';
import { IReservationDetail, ReservationEventStatusEnum } from '@outlook-addin/cue-http';

@Pipe({
  name: 'reservationStatus',
  standalone: true,
})
export class ReservationStatusPipe implements PipeTransform {
  transform(reservationDetail: IReservationDetail): ReservationEventStatusEnum {
    if (!reservationDetail) {
      return ReservationEventStatusEnum.Unknown;
    }
    if (reservationDetail.unconfirmedEventCanceled) {
      return ReservationEventStatusEnum.UnconfirmEventCanceled;
    }
    if (reservationDetail.cancelled) {
      return ReservationEventStatusEnum.Cancelled;
    }
    if (reservationDetail.confirmed) {
      return ReservationEventStatusEnum.Confirmed;
    }
    if (!reservationDetail.confirmed && reservationDetail.confirmationInfo?.confirmationRequired) {
      const currentDate = new Date(Date.now());
      if (currentDate > reservationDetail.confirmationInfo.notBefore && currentDate < reservationDetail.confirmationInfo.until) {
        return ReservationEventStatusEnum.Tentative;
      }
    }
    return ReservationEventStatusEnum.Unknown;
  }
}
