import { Directive, ElementRef, Injectable, Input, NgZone, OnDestroy } from '@angular/core';
import { animationFrameScheduler, fromEvent, observeOn, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SaveScrollService {
  container: { [key: string]: { scrollLeft: number; scrollTop: number } } = {};

  save(key, scrollState: { scrollLeft: number; scrollTop: number }) {
    this.container[key] = {
      scrollLeft: scrollState.scrollLeft,
      scrollTop: scrollState.scrollTop,
    };
  }

  get(key): { scrollLeft: number; scrollTop: number } {
    return this.container[key];
  }
}

@Directive({
  selector: '[saveScroll]',
  standalone: true,
})
export class SaveScrollDirective implements OnDestroy {
  @Input() saveScroll: string = 'default';
  @Input() saveScrollAxis: 'x' | 'y' | 'xy' = 'xy';
  @Input() defaultScrollLeft?: number;
  @Input() defaultScrollTop?: number;
  sub: Subscription;
  scrollLeft = 0;
  scrollTop = 0;

  constructor(
    private elementRef: ElementRef,
    private zone: NgZone,
    private saveScrollService: SaveScrollService,
  ) {
    const nativeElement = elementRef.nativeElement as HTMLDivElement;
    this.scrollLeft = nativeElement.scrollLeft;
    this.scrollTop = nativeElement.scrollTop;
    this.zone.runOutsideAngular(() => {
      this.sub = fromEvent(nativeElement, 'scroll').subscribe((event: any) => {
        this.scrollLeft = event.target.scrollLeft;
        this.scrollTop = event.target.scrollTop;
      });
    });
  }

  ngOnInit() {
    const savedState = this.saveScrollService.get(this.saveScroll);
    if (savedState) {
      requestAnimationFrame(() => {
        if (this.saveScrollAxis === 'xy' || this.saveScrollAxis === 'x') {
          this.elementRef.nativeElement.scrollLeft = savedState.scrollLeft;
        }
        if (this.saveScrollAxis === 'xy' || this.saveScrollAxis === 'y') {
          this.elementRef.nativeElement.scrollTop = savedState.scrollTop;
        }
      });
    } else {
      requestAnimationFrame(() => {
        if (this.defaultScrollLeft !== undefined) {
          this.elementRef.nativeElement.scrollLeft = this.defaultScrollLeft;
        }
        if (this.defaultScrollTop !== undefined) {
          this.elementRef.nativeElement.scrollTop = this.defaultScrollTop;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.saveScrollService.save(this.saveScroll, {
      scrollLeft: this.scrollLeft,
      scrollTop: this.scrollTop,
    });
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
