import { Pipe, PipeTransform } from '@angular/core';
import { AddinConfigService, ResourceStatus } from '@outlook-addin/shared';

export interface ResourceTimelineStateCrate {
  color: string;
  type: string;
}
@Pipe({
  name: 'resourceTimelineStateToColor',
  standalone: true,
})
export class ResourceTimelineStateToColorPipe implements PipeTransform {
  availStateList: ResourceStatus[];

  constructor(private configService: AddinConfigService) {
    this.availStateList = this.configService.value.configuration.statusesList;
  }

  transform(timelineWindowState: string): ResourceTimelineStateCrate {
    const item = this.availStateList?.filter((x) => x.status === +timelineWindowState)[0];
    if (item) {
      return {
        color: item.color,
        type: item.name,
      };
    }
    return null;
  }
}
