import { Component } from '@angular/core';
import { AzureAuthService, DashboardHttpService } from '@outlook-addin/cue-http';
import { Observable } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MeetingTimeSuggestionsResult } from '@microsoft/microsoft-graph-types';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'addin-dashboard',
  templateUrl: './meeting-finder.component.html',
  styleUrls: ['./meeting-finder.component.scss'],
  standalone: true,
  imports: [RouterModule, ReactiveFormsModule, CommonModule],
})
export class MeetingFinderComponent {
  form: FormGroup;

  meetingSuggestions$: Observable<MeetingTimeSuggestionsResult>;
  userToken$: Observable<any>;
  userToken: string;
  constructor(
    private dashboardHttpService: DashboardHttpService,
    private azureAuthService: AzureAuthService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      startDate: [new Date(Date.now()), Validators.required],
      endDate: [new Date(Date.now()), Validators.required],
      colleagueEmailList: this.formBuilder.array([
        // this.formBuilder.control('', [Validators.required,Validators.email])
      ]),
    });
  }

  get colleagueEmailList() {
    return this.form.get('colleagueEmailList') as FormArray;
  }

  addUser() {
    this.colleagueEmailList.push(this.formBuilder.control(null, [Validators.required, Validators.email]));
  }

  get loginVisible(): boolean {
    return !this.azureAuthService.isUserLogin();
  }

  deleteUser(index: number) {
    this.colleagueEmailList.removeAt(index);
    // this.colleagueList = this.colleagueList.filter(x=>x.toLowerCase() !== personName.toLowerCase())
  }

  callGraphApi() {
    this.dashboardHttpService.loadSummaryOverview().subscribe((x) => {
      console.log('DATA FROM GRAPH: ', x);
    });
  }

  getEmails() {
    this.dashboardHttpService.getEmails().subscribe((x) => {
      console.log('EMAILS: ', x);
    });
  }

  findMeetingTime() {
    if (this.form.valid) {
      this.meetingSuggestions$ = this.dashboardHttpService.findMeetingTime({
        startDate: new Date(this.form.value.startDate),
        endDate: new Date(this.form.value.endDate),
        colleagueList: this.colleagueEmailList.value,
      });
    } else {
      this.form.markAllAsTouched();
    }
  }
}
