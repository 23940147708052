import { CurrentUser, PackedCurrentUser } from '../models';
import { createAction, props } from '@ngrx/store';
import { AssistUserSettingsBM } from '@cue/api';

export const loadCurrentUser = createAction('[Current User] Load');
export const clearCurrentUser = createAction('[Current User] Clear');

export const currentUserLoaded = createAction('[Current User] Loaded', props<{ user: PackedCurrentUser }>());

export const loadCurrentUserSettings = createAction('[Current User Settings] Load');
export const currentUserSettingsLoaded = createAction('[Current User Settings] Loaded', props<{ userSettings: AssistUserSettingsBM }>());
