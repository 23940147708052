import { createSelector } from '@ngrx/store';

import { selectShared } from './shared.selectors';
import { allResources } from './resources.selectors';

export const selections = createSelector(selectShared, (state) => state.selections);

export const selectedEvents = createSelector(selections, (s) => s.selectedSchedules);
export const hoveredEvents = createSelector(selections, (s) => s.hoveredSchedules);

export const selectedResource = createSelector(
  allResources,
  selections,
  (_resources, _selections) =>
    !_resources.loading &&
    !_resources.loading &&
    (_resources.data!.find((x) => x.id === _selections.selectedResourceId) ??
      _resources.data!.find((x) => x.id === _selections.selectedResourceId)),
);

export const selectedResourceId = createSelector(selections, (_selections) => _selections.selectedResourceId);

export const selectedWorkplace = createSelector(
  allResources,
  selections,
  (_resources, _selections) => !_resources.loading && _resources.data!.find((x) => x.id === _selections.selectedResourceId),
);
