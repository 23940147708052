<kendo-textbox
    #textBox
    [cueClearable]="showClear"
    (focusin)="focus()"
    (focusout)="blur()"
    [formControl]="_control"
    [placeholder]="placeholder"
    [class.icon-before]="iconBefore"
    [class.clearable]="showClear && _control.value && _control.value.length > 0"
>
</kendo-textbox>
@if (iconBefore) {
    <cue-icon class="label-icon" [icon]="iconBefore"></cue-icon>
}
