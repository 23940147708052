import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { ReservationsHttpService, TouchOneHttpService } from '@outlook-addin/cue-http';
import { ReservationActions } from '../actions';
import { map, switchMap } from 'rxjs/operators';
import { betterLatestFrom } from '@assist/shared/data';
import { AppStateModel, selectEventDetail, State } from '../reducers';
import { Store } from '@ngrx/store';
import { loadCalendarEventFailed } from '../actions/reservation.actions';

@Injectable()
export class ReservationEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<State>,
    private httpService: ReservationsHttpService,
    private touchOneHttpService: TouchOneHttpService,
  ) {}

  loadReservationList = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReservationActions.loadReservationList),
      switchMap((action) => {
        const data$ = this.httpService.loadReservationList(action.filter);
        return data$.pipe(
          switchMap(async (result) => {
            return ReservationActions.reservationListLoaded({
              reservationList: result,
            });
          }),
        );
      }),
    );
  });

  loadEventDetail = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReservationActions.loadEventDetailFromGraph),
      switchMap((action) => {
        const data$ = this.touchOneHttpService.getICalUid(action.itemId);
        return data$.pipe(
          map((result) => {
            if (result) {
              return ReservationActions.loadReservationDetail({
                eventDetail: result,
              });
            } else {
              this.router.navigate(['../reservation/cannot-fetch']);
              return ReservationActions.loadCalendarEventFailed();
            }
          }),
        );
      }),
    );
  });

  refresgReservaitonDetail = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReservationActions.refreshReservationDetail),
      betterLatestFrom(() => this.store.select(selectEventDetail)),
      map(([_, eventDetail]) =>
        ReservationActions.loadReservationDetail({
          eventDetail: eventDetail,
        }),
      ),
    );
  });

  loadReservationDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReservationActions.loadReservationDetail),
      switchMap((action) => {
        const data$ = this.touchOneHttpService.loadReservationDetail({
          event: action.eventDetail,
        });
        return data$.pipe(
          map((result) => {
            this.router.navigate(['../reservation/a']);
            return !result.error
              ? ReservationActions.reservationDetailLoadSuccess({
                  detail: result.data,
                })
              : ReservationActions.reservationDetailLoadFailed({
                  error: result.error,
                });
          }),
        );
      }),
    );
  });
}
