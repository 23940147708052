import { Directive, NgZone, Optional } from '@angular/core';
import { DatePickerComponent, DateTimePickerComponent, TimePickerComponent } from '@progress/kendo-angular-dateinputs';

@Directive({
  selector: '[scrollPicker]',
  standalone: true,
})
export class ScrollPickerDirective {
  constructor(
    @Optional() dateTimePicker: DateTimePickerComponent,
    @Optional() timePicker: TimePickerComponent,
    @Optional() datePicker: DatePickerComponent,
    zone: NgZone,
  ) {
    const target = dateTimePicker ?? timePicker ?? datePicker;
    target.open.subscribe(() => {
      zone.runOutsideAngular(() => {
        setTimeout(() => {
          {
            (target as any).popupRef.popupElement.classList.add('scroll-picker');
          }
        });
      });
    });
  }
}
