import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { STORAGE_KEYS } from '@outlook-addin/cue-http';

@Component({
  selector: 'addin-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.scss'],
  standalone: true,
  imports: [],
})
export class AuthorizeComponent implements OnInit {
  constructor(
    private router: Router,
    private zone: NgZone,
  ) {}

  ngOnInit() {
    //due to problem with RouterModule.forRoot(routes, {useHash: true,}) get params does not work
    this.getCodeFromUrl();
  }

  private getCodeFromUrl() {
    if (localStorage.getItem(STORAGE_KEYS.code) == null) {
      //URL looks like https://localhost:4200/?code=123&session_state=aaa
      const uriPartWithCodeParam = window.location.search;
      const codeWithOtherParams = uriPartWithCodeParam?.split('?code=');

      //if code was found in URL
      if (codeWithOtherParams?.length === 2) {
        //trim session_state params from URI
        const codeParam = codeWithOtherParams[1].split('&session_state=');

        this.zone.run(() => {
          Office.onReady().then(() => {
            setTimeout(() => {
              Office.context.ui.messageParent(codeParam[0]);
              window.top.postMessage('code_received', '*');
            }, 500);
          });
        });
      } else {
        this.router.navigate(['login']);
      }
    }
  }
}
