import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './utils/jwt.interceptor';
import { provideCueHttp } from '@outlook-addin/cue-http';

export const coreConfig = () => {
  return {
    providers: [
      provideCueHttp(),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true,
      },
    ],
  };
};
