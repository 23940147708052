import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromShared from '../app/ngrx/reducers';
import { AccountActions } from './ngrx/actions';
import { translation } from '@assist/shared/translations';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'addin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  protected readonly translate = translate;
  title = 'outlook-addin';
  translation = translation;

  constructor(private store: Store<fromShared.State>) {
    window.onmessage = (e) => {
      if (e.data == 'code_received') {
        console.log('code_received');

        setTimeout(() => {
          this.store.dispatch(AccountActions.authCodeReceived());
          window.close();
        }, 1);
      }
    };
  }
}
