import { Injectable } from '@angular/core';
import { IReservationItem } from '../models/reservations.models';
import { CommonHttpService } from './common-http.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReservationsHttpService {
  private fakeData: IReservationItem[] = [
    {
      id: 'BBMkADZhZmY2N2ZkLWE3NzgtNGM1YS05YjFkLTBiNDY4ODZjMDFlNgBGAAAAAACtX5sdYQaJT5ptELmYImFYBwBd8YiQz7AOTayPVQyNm6ZWAAAAAAENAABd8YiQz7AOTayPVQyNm6ZWAALRTUwiAAA=',
      createdDateTime: new Date('2023-01-11T11:56:08.7310871Z'),
      lastModifiedDateTime: new Date('2023-01-11T11:58:10.8159607Z'),
      changeKey: 'XfGIkM+wDk2sj1UMjZumVgAC0Kw80Q==',
      categories: [],
      transactionId: null,
      originalStartTimeZone: 'Europe/Prague',
      originalEndTimeZone: 'Europe/Prague',
      iCalUId: '040000008200E00074C5B7101A82E00800000000FB8940B1B325D90100000000000000001000000029154F7FB314484A81151BE12BD86315',
      reminderMinutesBeforeStart: 15,
      isReminderOn: true,
      hasAttachments: false,
      subject: 'Reserved from CUE Assist',
      bodyPreview:
        "Please JOIN ME in time from Wednesday 11.01.23 14:15 to Wednesday 11.01.23 15:15 inRoom ' R D 0023 '.\r\n________________________________________________________________________________\r\nMicrosoft Teams meeting\r\nJoin on your computer, mobile app or room dev",
      importance: 'normal',
      sensitivity: 'normal',
      isAllDay: false,
      isCancelled: false,
      isOrganizer: true,
      seriesMasterId: null,
      showAs: 'busy',
      type: 'singleInstance',
      webLink:
        'https://outlook.office365.com/owa/?itemid=AAMkADZhZmY2N2ZkLWE3NzgtNGM1YS05YjFkLTBiNDY4ODZjMDFlNgBGAAAAAACtX5sdYQaJT5ptELmYImFYBwBd8YiQz7AOTayPVQyNm6ZWAAAAAAENAABd8YiQz7AOTayPVQyNm6ZWAALRTUwiAAA%3D&exvsurl=1&path=/calendar/item',
      onlineMeetingUrl: null,
      isOnlineMeeting: true,
      onlineMeetingProvider: 'teamsForBusiness',
      allowNewTimeProposals: true,
      occurrenceId: null,
      isDraft: false,
      hideAttendees: false,
      responseStatus: {
        response: 'organizer',
        time: '0001-01-01T00:00:00Z',
      },
      body: {
        contentType: 'html',
        content: 'content111',
      },
      start: {
        dateTime: new Date('2023-01-11T13:15:00.0000000'),
        timeZone: 'UTC',
      },
      end: {
        dateTime: new Date('2023-01-11T14:15:00.0000000'),
        timeZone: 'UTC',
      },
      location: {
        displayName: 'R D 0023',
        locationUri: 'dev_resource_0023@touchone.eu',
        locationType: 'default',
        uniqueId: 'R D 0023',
        uniqueIdType: 'private',
        address: {
          street: '',
          city: '',
          state: '',
          countryOrRegion: '',
          postalCode: '',
        },
        coordinates: {},
      },
      locations: [
        {
          displayName: 'R D 0023',
          locationUri: 'dev_resource_0023@touchone.eu',
          locationType: 'default',
          uniqueId: 'R D 0023',
          uniqueIdType: 'private',
          address: {
            street: '',
            city: '',
            state: '',
            countryOrRegion: '',
            postalCode: '',
          },
          coordinates: {},
        },
        {
          displayName: 'R D 0025',
          locationUri: 'dev_resource_0025@touchone.eu',
          locationType: 'default',
          uniqueId: 'R D 0025',
          uniqueIdType: 'private',
          address: {
            street: '',
            city: '',
            state: '',
            countryOrRegion: '',
            postalCode: '',
          },
          coordinates: {},
        },
      ],
      recurrence: null,
      attendees: [
        {
          type: 'required',
          status: {
            response: 'accepted',
            time: '2023-01-11T11:56:19.4353512Z',
          },
          emailAddress: {
            name: 'R D 0023',
            address: 'dev_resource_0023@touchone.eu',
          },
        },
      ],
      organizer: {
        emailAddress: {
          name: 'Adam Almer',
          address: 'adam.almer@touchone.eu',
        },
      },
      onlineMeeting: {
        joinUrl:
          'https://teams.microsoft.com/l/meetup-join/19%3ameeting_Y2NiNmE1ZDktZWE2Mi00YjgxLTk1NzUtZTFiOGY0MGI0Zjkx%40thread.v2/0?context=%7b%22Tid%22%3a%2290a42c52-3f62-4417-8517-e187ed3fdf60%22%2c%22Oid%22%3a%22b302c4ff-11e1-493e-97ea-267d94d44514%22%7d',
      },
    },
    {
      id: 'AAMkADZhZmY2N2ZkLWE3NzgtNGM1YS05YjFkLTBiNDY4ODZjMDFlNgBGAAAAAACtX5sdYQaJT5ptELmYImFYBwBd8YiQz7AOTayPVQyNm6ZWAAAAAAENAABd8YiQz7AOTayPVQyNm6ZWAALRTUwiAAA=',
      createdDateTime: new Date('2023-01-11T11:56:08.7310871Z'),
      lastModifiedDateTime: new Date('2023-01-11T11:58:10.8159607Z'),
      changeKey: 'XfGIkM+wDk2sj1UMjZumVgAC0Kw80Q==',
      categories: [],
      transactionId: null,
      originalStartTimeZone: 'Europe/Prague',
      originalEndTimeZone: 'Europe/Prague',
      iCalUId: '040000008200E00074C5B7101A82E00800000000FB8940B1B325D90100000000000000001000000029154F7FB314484A81151BE12BD86315',
      reminderMinutesBeforeStart: 15,
      isReminderOn: true,
      hasAttachments: false,
      subject: 'Reserved from CUE Assist',
      bodyPreview:
        "Please JOIN ME in time from Wednesday 11.01.23 14:15 to Wednesday 11.01.23 15:15 inRoom ' R D 0023 '.\r\n________________________________________________________________________________\r\nMicrosoft Teams meeting\r\nJoin on your computer, mobile app or room dev",
      importance: 'normal',
      sensitivity: 'normal',
      isAllDay: false,
      isCancelled: false,
      isOrganizer: true,
      seriesMasterId: null,
      showAs: 'busy',
      type: 'singleInstance',
      webLink:
        'https://outlook.office365.com/owa/?itemid=AAMkADZhZmY2N2ZkLWE3NzgtNGM1YS05YjFkLTBiNDY4ODZjMDFlNgBGAAAAAACtX5sdYQaJT5ptELmYImFYBwBd8YiQz7AOTayPVQyNm6ZWAAAAAAENAABd8YiQz7AOTayPVQyNm6ZWAALRTUwiAAA%3D&exvsurl=1&path=/calendar/item',
      onlineMeetingUrl: null,
      isOnlineMeeting: true,
      onlineMeetingProvider: 'teamsForBusiness',
      allowNewTimeProposals: true,
      occurrenceId: null,
      isDraft: false,
      hideAttendees: false,
      responseStatus: {
        response: 'organizer',
        time: '0001-01-01T00:00:00Z',
      },
      body: {
        contentType: 'html',
        content: 'content222',
      },
      start: {
        dateTime: new Date('2023-01-11T13:15:00.0000000'),
        timeZone: 'UTC',
      },
      end: {
        dateTime: new Date('2023-01-11T14:15:00.0000000'),
        timeZone: 'UTC',
      },
      location: {
        displayName: 'R D 0023',
        locationUri: 'dev_resource_0023@touchone.eu',
        locationType: 'default',
        uniqueId: 'R D 0023',
        uniqueIdType: 'private',
        address: {
          street: '',
          city: '',
          state: '',
          countryOrRegion: '',
          postalCode: '',
        },
        coordinates: {},
      },
      locations: [
        {
          displayName: 'R D 0023',
          locationUri: 'dev_resource_0023@touchone.eu',
          locationType: 'default',
          uniqueId: 'R D 0023',
          uniqueIdType: 'private',
          address: {
            street: '',
            city: '',
            state: '',
            countryOrRegion: '',
            postalCode: '',
          },
          coordinates: {},
        },
        {
          displayName: 'R D 0025',
          locationUri: 'dev_resource_0025@touchone.eu',
          locationType: 'default',
          uniqueId: 'R D 0025',
          uniqueIdType: 'private',
          address: {
            street: '',
            city: '',
            state: '',
            countryOrRegion: '',
            postalCode: '',
          },
          coordinates: {},
        },
      ],
      recurrence: null,
      attendees: [
        {
          type: 'required',
          status: {
            response: 'accepted',
            time: '2023-01-11T11:56:19.4353512Z',
          },
          emailAddress: {
            name: 'R D 0023',
            address: 'dev_resource_0023@touchone.eu',
          },
        },
      ],
      organizer: {
        emailAddress: {
          name: 'Adam Almer',
          address: 'adam.almer@touchone.eu',
        },
      },
      onlineMeeting: {
        joinUrl:
          'https://teams.microsoft.com/l/meetup-join/19%3ameeting_Y2NiNmE1ZDktZWE2Mi00YjgxLTk1NzUtZTFiOGY0MGI0Zjkx%40thread.v2/0?context=%7b%22Tid%22%3a%2290a42c52-3f62-4417-8517-e187ed3fdf60%22%2c%22Oid%22%3a%22b302c4ff-11e1-493e-97ea-267d94d44514%22%7d',
      },
    },
  ];
  constructor(protected http: CommonHttpService) {}

  loadReservationListDEP(): Observable<IReservationItem[]> {
    return of(this.fakeData);
  }

  loadReservationList(filter: any): Observable<IReservationItem[]> {
    //let fromDate = new Date(Date.now());
    //let toDate = new Date(Date.now()+1000*60*60*24*365);
    //
    //if(filter){
    //  fromDate = new Date(Date.now()-1000*60*60*24*365);
    //  toDate = new Date(Date.now());
    //}
    //
    //const url = `https://graph.microsoft.com/v1.0/me/calendarView?startDateTime=${fromDate.toUTCString()}&endDateTime=${toDate.toUTCString()}&top=30&$skip=0`;
    //
    //return this.http.get<
    //  any,
    //  IReservationItem[]
    //>(url, (dataContext) => {
    //  return dataContext.value.map((x: any)=>{
    //    return x;
    //  });
    //});
    if (filter) {
      return of(this.fakeData.filter((x) => x.end.dateTime > new Date(Date.now())));
    } else {
      return of(this.fakeData);
    }
  }

  loadReservationDetail(id: string): Observable<any> {
    return of(this.fakeData[0]);
  }
}
