import { RouterStateSerializer } from '@ngrx/router-store';
import { Data, Params, RouterStateSnapshot } from '@angular/router';
import { Pack } from '@assist/shared/data';

export interface IBaseContextData {
  context: string;
}

export interface RouterStateModel {
  url: string;
  params: Params;
  queryParams: Params;
  data: Data;
}

export class RouterSerializer implements RouterStateSerializer<RouterStateModel> {
  serialize(routerState: RouterStateSnapshot): RouterStateModel {
    let route = routerState.root;
    const params = [];
    const queryParams = [];
    const data = [];
    let helperObject = {};

    while (route.firstChild) {
      route = route.firstChild;

      if (route !== null) {
        Object.keys(route.params).forEach((x) => {
          helperObject = {};
          helperObject[x] = route.params[x];
          params.push(helperObject);
        });
        Object.keys(route.queryParams).forEach((x) => {
          helperObject = {};
          helperObject[x] = route.queryParams[x];
          queryParams.push(helperObject);
        });
        Object.keys(route.data).forEach((x) => {
          helperObject = {};
          helperObject[x] = route.data[x];
          data.push(helperObject);
        });
      }
    }

    const { url } = routerState;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams, data };
  }
}
