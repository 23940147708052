@if (!isRestrictedResource) {
    @if (!isSameDate()) {
        <p>
            {{ translation.outlookAddin.availability | transloco }}
            {{ startDate | date: 'dd.MM. HH:mm' }} - {{ endDate | date: 'dd.MM. HH:mm' }}
        </p>
    }
    @if (isSameDate()) {
        <p>
            {{ translation.outlookAddin.availability | transloco }} {{ startDate | date: 'HH:mm' }} -
            {{ endDate | date: 'HH:mm' }}
        </p>
    }

    <div class="timeline">
        @for (timeWindow of timelineWindowList; track timeWindow) {
            @if (timeWindow | resourceTimelineStateToColor; as item) {
                <span [style.background]="item.color"></span>
            }
        }
    </div>
    <div class="times">
        <span>
            @if (!isSameDate()) {
                {{ startDate | date: 'dd.MM. HH:mm' }}
            }
            @if (isSameDate()) {
                {{ startDate | date: 'HH:mm' }}
            }
        </span>
        <span>
            @if (!isSameDate()) {
                {{ endDate | date: 'dd.MM. HH:mm' }}
            }
            @if (isSameDate()) {
                {{ endDate | date: 'HH:mm' }}
            }
        </span>
    </div>
}

@if (isRestrictedResource) {
    <p class="text-center">
        {{ translation.outlookAddin.resourceRestricted | transloco }}
    </p>
    <span class="text-center restricted-description">
        {{ translation.outlookAddin.chooseAnotherResource | transloco }}
    </span>
}
