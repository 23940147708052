import { inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class FaviconService {
  private readonly document = inject(DOCUMENT);

  private originalFavicon?: string;

  setFavicon(favicon: string) {
    let link: HTMLLinkElement = this.document.querySelector("link[rel~='icon']");

    if (!link) {
      link = this.document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }

    if (!this.originalFavicon) {
      this.originalFavicon = link.href;
    }
    link.href = favicon;
  }

  resetFavicon() {
    if (this.originalFavicon) {
      this.setFavicon(this.originalFavicon);
    }
  }
}
