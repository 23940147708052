export type LocalizedString = {
  ɵvalue: string;
};
export type CueAppLocale = 'en' | 'cs' | 'de' | 'es' | 'fr' | 'no' | 'nb' | 'ua';

export function fromLocalized(
  localizedString: LocalizedString,
  locale: string,
  defaultLocale: string,
  fallbackToDefault: boolean = true,
): string {
  if (localizedString === null || localizedString === undefined) {
    return localizedString as unknown as string;
  }
  const value = localizedString[locale];
  if (value != undefined) {
    return value;
  }
  if (fallbackToDefault) {
    return localizedString[defaultLocale];
  }
  return value;
}

export function isLocalizedString(value: string | LocalizedString): value is LocalizedString {
  return value !== null && value !== undefined && typeof value === 'object';
}
