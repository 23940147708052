import {
  Directive,
  HostBinding,
  forwardRef,
  OnInit,
  Input,
  HostListener,
  Optional,
  Injectable,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { Dimensions, Display } from '../models';
import { ImageStateService } from '../services';
import { PLACEHOLDER_IMAGE_IMAGE } from '../../index';

@Directive({
  selector: '[cueProgressiveImage]',
  standalone: true,
  providers: [
    {
      provide: Dimensions,
      useExisting: forwardRef(() => ProgressiveImageDirective),
    },
  ],
})
export class ProgressiveImageDirective implements OnInit, Dimensions {
  @HostBinding('style.display') display = Display.none;
  @HostBinding('style.width') width: number;
  @HostBinding('style.height') height: number;
  @HostBinding('style.max-height') maxHeight: number;

  private _src: string;
  @HostBinding('attr.src')
  @Input()
  set src(src: string) {
    this.imageState.emitLoading();
    this._src = src;
  }

  get src() {
    return this._src;
  }

  @HostListener('load')
  onLoad() {
    this.imageState.emitLoaded();
  }

  @HostListener('error')
  onError() {
    this.imageState.emitError();
  }

  constructor(
    @Optional() @Inject(PLACEHOLDER_IMAGE_IMAGE) private placeholderImage: string,
    private cdr: ChangeDetectorRef,
    private imageState: ImageStateService,
  ) {}

  ngOnInit() {
    this.imageState.isLoaded$.subscribe((loaded) => {
      if (loaded) {
        this.display = Display.flex;
      } else {
        if (this.placeholderImage) {
          this.display = Display.flex;
          this._src = this.placeholderImage;
        } else {
          this.display = Display.none;
        }
      }
    });
  }
}
