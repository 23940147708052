import { Injectable } from '@angular/core';
import { AuthHttpService } from './auth-http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AzureAuthService {
  public authenticated: boolean;
  public user?: any;

  constructor(private authService: AuthHttpService) {
    this.authenticated = false;
    this.user = undefined;
  }

  isUserLogin() {
    return !this.authService.isAccessTokenExpired();
  }

  getTokenByAuthCode(): Observable<string> {
    return this.authService.signIn();
  }
}
