import { createAction, props } from '@ngrx/store';
import { IdentifiedAssistEvent } from '../models';

export const loadReservations = createAction('[Reservations] Load', props<{ from: Date; to: Date; nextLink?: string }>());
export const loadMoreReservations = createAction('[Reservations] LoadMore');
export const setReservationsFilter = createAction(
  '[Reservations] SetFilter',
  props<{
    from: Date;
    to: Date;
    attendees: string[];
    resourceNames: string[];
    state: any;
  }>(),
);
export const refreshReservations = createAction('[Reservations] Refresh');
export const loadReservationsSuccess = createAction(
  '[Reservations] Load Success',
  props<{ events: IdentifiedAssistEvent[]; nextLink: string }>(),
);
