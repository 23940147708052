import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, EffectNotification, OnRunEffects } from '@ngrx/effects';

import { map, switchMap, filter, skipUntil } from 'rxjs/operators';

import * as AreasActions from '../actions/areas.actions';
import { Observable, of } from 'rxjs';
import { DbService } from '../services';

@Injectable()
export class AreasEffects implements OnRunEffects {
  // Remove after server
  loadAreasAfterInitialized$ = createEffect(() =>
    this.dbService.initialized$.pipe(
      filter((x) => x),
      map((_) => AreasActions.loadAreas()),
    ),
  );

  loadAreas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AreasActions.loadAreas),
      switchMap((_) => of(this.dbService.areas)),
      map((areas) => AreasActions.areasLoaded({ areas: areas })),
    ),
  );

  constructor(
    private actions$: Actions,
    private dbService: DbService,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return resolvedEffects$.pipe(skipUntil(this.dbService.initialized$));
  }
}
