import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AppState, initOperation, OperationsState, SharedState } from '../models';
import { selectShared } from './shared.selectors';

export const operations = createSelector<AppState, SharedState, OperationsState>(selectShared, (state) => state.operations);
export const operationByCorrelationId = createSelector(operations, (state: OperationsState, props: { correlationId: string }) =>
  state.byCorrelationId[props.correlationId] ? state.byCorrelationId[props.correlationId] : initOperation(null, props.correlationId),
);

export const operationByType = createSelector(operations, (state: OperationsState, props: { type: string }) =>
  state.byType[props.type] ? state.byType[props.type][0] : initOperation(props.type, null),
);
