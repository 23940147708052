import { ChangeDetectionStrategy, Component, computed, input, Input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { translation } from '@assist/shared/translations';
import { cueArrowDown, cueExclamation, IconComponent } from '@cue/ui/icons';
import { formatInTimeZone, getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import { getTimeZoneFromOfficeString } from '@assist/shared/data';
import { findIanaWindowsMappingFromUnkown } from '@cue/calendars';
import { addDays, differenceInMinutes, isWithinInterval, set } from 'date-fns';

export type WorkingHourInfo = {
  startTime?: string;
  endTime?: string;
  timezone: string;
  daysOfWeek: string[];
};

@Component({
  selector: 'cue-working-hours',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslocoModule],
  templateUrl: './working-hours.component.html',
  styleUrls: ['./working-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkingHoursComponent {
  workingHourInfo = input.required<WorkingHourInfo>();
  relativeNow = input<Date>();

  ICONS = {
    cueArrowDown,
    cueExclamation,
  };

  ianaTimeZone = computed(() => {
    return findIanaWindowsMappingFromUnkown(this.workingHourInfo().timezone).iana[0];
  });

  isDifferentTimezone = computed(() => {
    // ofsset from IANA timezone name
    const offset = getTimezoneOffset(this.ianaTimeZone());
    const offset2 = getTimezoneOffset(this.currentIanaTimezone());
    return offset !== offset2;
  });

  currentIanaTimezone = computed(() => {
    return findIanaWindowsMappingFromUnkown(Intl.DateTimeFormat().resolvedOptions().timeZone).iana[0];
  });

  daysForLocale = computed(() => {
    const resourceTimeZone = getTimeZoneFromOfficeString(this.workingHourInfo().timezone);
    const startTimeDay = formatInTimeZone(this.now(), resourceTimeZone, 'iiii').toLowerCase();

    return this.daysOfWeek.map((day) => {
      const foundDayOfWeek = this.workingHourInfo().daysOfWeek.find((d) => d === day);
      const active = !!foundDayOfWeek;
      return {
        display: day,
        active: active,
        today: day === startTimeDay,
      };
    });
  });

  daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  accordionExpanded = false;
  translation = translation;

  getDayTranslation(day: string) {
    return this.translation.workingHours[day];
  }

  openClosedCaption = computed(() => {
    if (this.tillStart().hours === 0 && this.tillStart().minutes === 0) {
      return !this.relativeNow() ? this.translation.workingHours.open : this.translation.workingHours.openEvent;
    } else {
      return !this.relativeNow() ? this.translation.workingHours.closed : this.translation.workingHours.closedEvent;
    }
  });

  whenOpensCaption = computed(() => {
    return !this.relativeNow() ? this.translation.workingHours.whenOpens : this.translation.workingHours.whenOpensEvent;
  });

  isOpen = computed(() => {
    return this.tillStart().hours === 0 && this.tillStart().minutes === 0;
  });

  now = computed(() => {
    return this.relativeNow() || new Date();
  });

  tillStart = computed(() => {
    const resourceTimeZone = getTimeZoneFromOfficeString(this.workingHourInfo().timezone);

    const nowInResourceZone = utcToZonedTime(this.now(), resourceTimeZone);
    const startTimeDay = formatInTimeZone(this.now(), resourceTimeZone, 'iiii');
    const startTimeInWorkDays = this.workingHourInfo().daysOfWeek.some((dow) => dow.toUpperCase() === startTimeDay.toUpperCase());

    const startTimeHours = this.workingHourInfo().startTime ? parseInt(this.workingHourInfo().startTime.split(':')[0], 10) : 0;
    const startTimeMinutes = this.workingHourInfo().startTime ? parseInt(this.workingHourInfo().startTime.split(':')[1], 10) : 0;
    const endTimeHours = this.workingHourInfo().endTime ? parseInt(this.workingHourInfo().endTime.split(':')[0], 10) : 23;
    const endTimeMinutes = this.workingHourInfo().endTime ? parseInt(this.workingHourInfo().endTime.split(':')[1], 10) : 59;

    const start = set(nowInResourceZone, {
      hours: startTimeHours,
      minutes: startTimeMinutes,
    });

    const end = set(nowInResourceZone, {
      hours: endTimeHours,
      minutes: endTimeMinutes,
    });
    const startTimeBetween = isWithinInterval(nowInResourceZone, {
      start: start,
      end: end,
    });

    if (startTimeInWorkDays && startTimeBetween) {
      return { hours: 0, minutes: 0 };
    } else {
      const indexOfStartTimeDay = this.daysOfWeek.indexOf(startTimeDay.toLowerCase());
      let dffMins = 0;
      if (startTimeInWorkDays && nowInResourceZone < start) {
        dffMins = Math.abs(differenceInMinutes(start, nowInResourceZone));
      } else {
        for (let i = 1; i < 7; i++) {
          const nextDay = this.daysOfWeek[(indexOfStartTimeDay + i) % 7];
          if (this.workingHourInfo().daysOfWeek.some((dow) => dow.toUpperCase() === nextDay.toUpperCase())) {
            const nextDayDate = set(addDays(nowInResourceZone, i), {
              hours: startTimeHours,
              minutes: startTimeMinutes,
            });
            dffMins = Math.abs(differenceInMinutes(nextDayDate, nowInResourceZone));
            break;
          }
        }
      }
      const hours = Math.floor(dffMins / 60);
      const minutes = dffMins % 60;
      return { hours, minutes };
    }
  });

  startHours = computed(() => {
    // generate date with time string in format "00:00:00.000
    const date = new Date();
    const hours = this.workingHourInfo().startTime ? this.workingHourInfo().startTime.split(':')[0] : 0;
    const minutes = this.workingHourInfo().startTime ? this.workingHourInfo().startTime.split(':')[1] : 0;
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));
    return date;
  });

  endHours = computed(() => {
    // generate date with time string in format "00:00:00.000
    const date = new Date();
    const hours = this.workingHourInfo().endTime ? this.workingHourInfo().endTime.split(':')[0] : 23;
    const minutes = this.workingHourInfo().endTime ? this.workingHourInfo().endTime.split(':')[1] : 59;
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));
    return date;
  });

  resourceTimeZoneOffset = computed(() => {
    const locale = Intl.DateTimeFormat().resolvedOptions().locale;
    const offset = getTimezoneOffset(this.ianaTimeZone()) / 1000 / 60;
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    const sign = offset < 0 ? '-' : '+';
    return `${sign}${hours.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:${minutes.toLocaleString(locale, { minimumIntegerDigits: 2, useGrouping: false })}`;
  });
}
