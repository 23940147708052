import {
  AfterViewInit,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  Optional,
  ViewContainerRef,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { FormControl, NgControl } from '@angular/forms';
import { cueClose, IconComponent } from '@cue/ui/icons';

@Directive({
  selector: '[cueClearable]',
  standalone: true,
  host: {
    class: 'has-clear',
  },
})
export class ClearableDirective implements AfterViewInit, OnDestroy {
  @Input() cueClearable: boolean;
  _sub: Subscription;

  constructor(
    @Optional() private control: NgControl,
    @Optional() private formControl: FormControl,
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private elRef: ElementRef,
    private factoryComponentResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) {}

  _componentRef: ComponentRef<IconComponent> | null = null;

  detachIcon() {
    if (!this._componentRef) {
      return;
    }
    if (this._sub) {
      this._sub.unsubscribe();
    }
    if (this._componentRef) {
      const index = this.viewContainer.indexOf(this._componentRef.hostView);
      this.viewContainer.detach(index);
      this._componentRef.destroy();
      this._componentRef = null;
    }
  }

  attachIcon() {
    if (this._componentRef) {
      return;
    }
    if (!this._componentRef) {
      const factory = this.factoryComponentResolver.resolveComponentFactory(IconComponent);
      this._componentRef = this.viewContainer.createComponent(factory, 0, this.injector);
      (this._componentRef.location.nativeElement as HTMLElement).classList.add('clear');
      this._componentRef.instance.icon = cueClose;
      this._componentRef.changeDetectorRef.detectChanges();
      this._sub = fromEvent(this._componentRef.location.nativeElement, 'click').subscribe(() => {
        this.clear();
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.cueClearable) {
      if (!this.control) {
        this.attachIcon();
      } else {
        this.bindControl();
        if (this.control.value?.length > 0) {
          this.attachIcon();
        } else {
          this.detachIcon();
        }
      }
    }
  }

  bindControl() {
    this.control.valueChanges.subscribe((value) => {
      if (value?.length > 0) {
        this.attachIcon();
      } else {
        this.detachIcon();
      }
    });
  }

  clear() {
    if (this.control) {
      this.control.control?.setValue('');
      this.cdr.markForCheck();
    } else {
      this.elRef.nativeElement.value = '';
    }
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }
}
