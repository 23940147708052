import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { set } from 'date-fns';
import { findWindowsFromIana, WINDOWS_TO_IANA_MAP } from '../services/implementations';

export class CalendarEventDatetime {
  private _localDateTime: string;
  private _ianaTimeZone: string;
  private _originalOfficeTimezone: string;
  private _utcDateTime: Date;

  get localDateTimeString(): string {
    return this._localDateTime;
  }

  get ianaTimeZone(): string {
    return this._ianaTimeZone;
  }

  get utcDateTime(): Date {
    return this._utcDateTime;
  }

  get officeTimeZone() {
    return this._originalOfficeTimezone ?? findWindowsFromIana(this._ianaTimeZone);
  }

  get officeString() {
    return set(this._utcDateTime, {
      milliseconds: 0,
      seconds: 0,
    })
      .toLocaleString('sv')
      .replace(' ', 'T');
  }

  normalize(): CalendarEventDatetime {
    const normalized = this.officeString;
    return new CalendarEventDatetime(normalized, this._ianaTimeZone);
  }

  static fromBrowserDatetime(datetime: Date) {
    return new CalendarEventDatetime(
      set(datetime, {
        milliseconds: 0,
        seconds: 0,
      })
        .toLocaleString('sv')
        .replace(' ', 'T'),
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    );
  }

  static fromUTCIANA(utcDateTime: string, ianaTimezone: string): CalendarEventDatetime {
    const foundIana = WINDOWS_TO_IANA_MAP.find((x) => x.iana.find((y) => y.toLowerCase() === ianaTimezone.toLowerCase()));
    if (!foundIana) {
      throw new Error(`Timezone ${ianaTimezone} not found in IANA database`);
    }
    const localDateTime = utcToZonedTime(utcDateTime, ianaTimezone);
    return new CalendarEventDatetime(
      set(localDateTime, {
        milliseconds: 0,
        seconds: 0,
      })
        .toLocaleString('sv')
        .replace(' ', 'T'),
      ianaTimezone,
    );
  }

  static fromLocalIANA(localDateTime: string, ianaTimezone: string): CalendarEventDatetime {
    const foundIana = WINDOWS_TO_IANA_MAP.find((x) => x.iana.find((y) => y.toLowerCase() === ianaTimezone.toLowerCase()));
    if (!foundIana) {
      throw new Error(`Timezone ${ianaTimezone} not found in IANA database`);
    }
    return new CalendarEventDatetime(localDateTime, ianaTimezone);
  }

  static fromLocalWindows(localDateTime: string, timeZone: string): CalendarEventDatetime {
    const foundIana = WINDOWS_TO_IANA_MAP.find((x) => x.windowsName.toLowerCase() === timeZone.toLowerCase());
    if (!foundIana) {
      throw new Error(`Timezone ${timeZone} not found in Windows database`);
    }
    return new CalendarEventDatetime(localDateTime, foundIana.iana[0], timeZone);
  }

  private constructor(localDateTime: string, ianaTimeZone: string, officeTimeZone?: string) {
    this._localDateTime = localDateTime;
    this._ianaTimeZone = ianaTimeZone;
    this._originalOfficeTimezone = officeTimeZone;
    this._utcDateTime = zonedTimeToUtc(localDateTime, ianaTimeZone);
  }
}
