import { Portal } from '../models';
import { createReducer, on, Action } from '@ngrx/store';
import * as PortalActions from '../actions/portal.actions';

export const initialState: Portal = {
  filtersOpened: false,
};

const reducer = createReducer(
  initialState,
  on(PortalActions.toggleFiltersOpen, (state, {}) => ({
    ...state,
    filtersOpened: !state.filtersOpened,
  })),
);

export function portalReducer(state: Portal, action: Action) {
  return reducer(state, action);
}
