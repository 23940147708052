export enum CalendarAvailability {
  free = '0',
  tentative = '1',
  reserved = '2',
  closed = '3',
  limited = '4',
  assignedToYou = '5',
  maxUtilization = '6',
  assignedToOthers = '7',
  currentEvent = '9',
  // resource has not working hours, but it is allowed to be booked outside of working hours
  freeOutsideWorkingHours = '8',
}
