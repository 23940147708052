import { PackedUser, PackedSchedules } from '../models';
import { createReducer, on, Action } from '@ngrx/store';
import * as SchedulesActions from '../actions/schedules.actions';

export const initialSchedulesState: PackedSchedules = {
  loading: false,
  data: [],
};

export const initialFloorplanSchedulesState: PackedSchedules = {
  loading: false,
  data: [],
};

export const initialQuickSchedulesState: PackedSchedules = {
  loading: false,
  data: [],
};

const quick_schedules_reducer = createReducer(
  initialSchedulesState,
  on(SchedulesActions.refreshQuickSchedules, (state) => ({
    ...state,
    loading: true,
  })),
  on(SchedulesActions.quickSchedulesLoaded, (state, { schedules }) => ({
    ...state,
    loading: false,
    data: schedules,
  })),
);

const schedules_reducer = createReducer(
  initialSchedulesState,
  on(SchedulesActions.refreshSchedules, (state) => ({
    ...state,
    loading: true,
  })),
  on(SchedulesActions.schedulesLoaded, (state, { schedules }) => ({
    ...state,
    loading: false,
    data: schedules,
  })),
);

const floorplan_schedules_reducer = createReducer(
  initialSchedulesState,
  on(SchedulesActions.refreshFloorplanSchedules, (state) => ({
    ...state,
    loading: true,
    data: [],
  })),
  on(SchedulesActions.floorplanSchedulesLoaded, (state, { schedules }) => ({
    ...state,
    loading: false,
    data: schedules,
  })),
);

export function schedulesReducer(state: PackedSchedules, action: Action) {
  return schedules_reducer(state, action);
}

export function quickSchedulesReducer(state: PackedSchedules, action: Action) {
  return quick_schedules_reducer(state, action);
}

export function floorplanSchedulesReducer(state: PackedSchedules, action: Action) {
  return floorplan_schedules_reducer(state, action);
}
