import { PackedUser, IdentifiedAssistEvent, Pack } from '../models';
import { createReducer, on, Action } from '@ngrx/store';
import * as EventsActions from '../actions/events.actions';

export const initialState: Pack<IdentifiedAssistEvent[]> = {
  loading: false,
  data: [],
};

const reducer = createReducer(
  initialState,
  on(EventsActions.loadEventsPatched, (state) => ({
    ...state,
    loading: true,
  })),
  on(EventsActions.eventsLoaded, (state, { events }) => ({
    ...state,
    loading: false,
    data: events,
  })),
  on(EventsActions.eventResponded, (state, { attendee, id, showChangeButton }) => ({
    ...state,
    loading: false,
    data: state.data.map((event) => {
      if (event.id === id) {
        return {
          ...event,
          showChangeButton: showChangeButton,
          attendees: event.attendees.map((att) => (att.emailAddress.address == attendee.emailAddress.address ? attendee : att)),
          accepted: attendee.status.response === 'accepted' || attendee.status.response === 'Accept',
        };
      } else {
        return event;
      }
    }),
  })),
);

export function eventsReducer(state: Pack<IdentifiedAssistEvent[]>, action: Action) {
  return reducer(state, action);
}
