import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class CommonHttpService {
  constructor(
    protected http: HttpClient,
    @Inject(LOCALE_ID) protected locale: string,
  ) {}

  private getResponseObject<ServerType, ClientType>(response: ServerType, mapFce?: (rawData: ServerType) => ClientType): ClientType {
    const data = mapFce != null ? mapFce(response as ServerType) : response;
    return data as ClientType;
  }

  private errorProject(error: HttpErrorResponse): Observable<any> {
    let er: any = null;
    if (error.error) {
      er = error.error;
    } else {
      if (error.message) {
        er = error.message;
      }
    }
    if (er) {
      console.log(er);
    }

    return of(null);
  }

  public get<ServerType, ClientType, ErrorType = any>(
    url: string,
    mapFce?: (rawData: ServerType) => ClientType,
    errorFce?: (error: HttpErrorResponse) => ErrorType,
  ): Observable<ClientType> {
    return this.http.get<ServerType>(url, undefined).pipe(
      map((res: any) => {
        return this.getResponseObject<ServerType, ClientType>(res, mapFce);
      }),
      catchError((error: HttpErrorResponse) => (errorFce ? of(errorFce(error)) : this.errorProject(error))),
    );
  }

  public getBlob(url: string): Observable<Blob | null> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        if (err) {
          console.log(err);
        }

        return of(null);
      }),
    );
  }

  public post<ServerType, ClientType, ErrorType = any>(
    url: string,
    body: any,
    mapFce?: (rawData: ServerType) => ClientType,
    errorFce?: (error: HttpErrorResponse) => ErrorType,
  ): Observable<ClientType> {
    return this.http.post<ServerType>(url, body, undefined).pipe(
      map((res: any) => {
        return this.getResponseObject<ServerType, ClientType>(res, mapFce);
      }),
      catchError((error: HttpErrorResponse) => (errorFce ? of(errorFce(error)) : this.errorProject(error))),
    );
  }

  public put<ServerType, ClientType>(url: string, body: any, mapFce?: (rawData: ServerType) => ClientType): Observable<ClientType> {
    return this.http.put<ServerType>(url, body, undefined).pipe(
      map((res: any) => {
        return this.getResponseObject<ServerType, ClientType>(res, mapFce);
      }),
      catchError((error: HttpErrorResponse) => this.errorProject(error)),
    );
  }

  public delete<ServerType, ClientType>(url: string, mapFce?: (rawData: ServerType) => ClientType): Observable<ClientType> {
    return this.http.delete<ServerType>(url, undefined).pipe(
      map((res: any) => {
        return this.getResponseObject<ServerType, ClientType>(res, mapFce);
      }),
      catchError((error: HttpErrorResponse) => this.errorProject(error)),
    );
  }

  public patch<ServerType, ClientType>(url: string, body: any, mapFce?: (rawData: ServerType) => ClientType): Observable<ClientType> {
    return this.http.patch<ServerType>(url, body, undefined).pipe(
      map((res: any) => {
        return this.getResponseObject<ServerType, ClientType>(res, mapFce);
      }),
      catchError((error: HttpErrorResponse) => this.errorProject(error)),
    );
  }
}
