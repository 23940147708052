import { createAction, props } from '@ngrx/store';

export const addHoverEvent = createAction(
  '[Selections] Add Hover to Event',
  props<{
    scheduleId: string;
    eventId: string;
    requirementId: string;
    resourceId: string;
  }>(),
);

export const removeHoverEvent = createAction('[Selections] Remove Hover From Event', props<{ scheduleId: string; eventId: string }>());

export const selectResource = createAction('[Resources] Select Resource', props<{ resourceId: string }>());
