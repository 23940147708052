@if (dataPack$ | async; as pack) {
    @if (pack.error) {
        {{ translation.outlookAddin.errorDescription | transloco }}
        <button (click)="refresh()" class="small">
            <cue-icon [icon]="ICONS.cueRefresh"></cue-icon>
            {{ translation.outlookAddin.refresh | transloco }}
        </button>
    }
    @if (!pack.error) {
        @if (pack.data; as reservationDetail) {
            <div class="mobile-ui">
                <div class="name">
                    @if (reservationDetail.subject) {
                        <h2>{{ reservationDetail.subject }}</h2>
                    } @else {
                        <h2 class="no-subject">
                            {{ translation.outlookAddin.noSubject | transloco }}
                        </h2>
                    }
                    <ng-template #noSubject>
                        <h2 class="no-subject">
                            {{ translation.outlookAddin.noSubject | transloco }}
                        </h2>
                    </ng-template>

                    @switch (reservationDetail | reservationStatus) {
                        @case (reservationEventStatusEnum.Confirmed) {
                            <span class="accepted">{{ translation.common.accepted | transloco }}</span>
                        }
                        @case (reservationEventStatusEnum.Cancelled) {
                            <span class="canceled">{{ translation.common.cancelled | transloco }}</span>
                        }
                        @case (reservationEventStatusEnum.Tentative) {
                            <span class="tentative">{{ translation.common.tentative | transloco }}</span>
                        }
                        @case (reservationEventStatusEnum.UnconfirmEventCanceled) {
                            <span class="canceled">{{ translation.common.unconfirmed | transloco }}</span>
                        }
                    }
                </div>
                <div class="tabs-container">
                    <div class="tabs">
                        <div class="tab-item" [class.active]="activeTabIndex === 0" #tab (click)="setActiveTab(0)">
                            <span>{{ translation.outlookAddin.locationDetail | transloco }}</span>
                        </div>
                        <div
                            class="tab-item"
                            [class.active]="activeTabIndex === 1"
                            [class.disabled]="reservationDetail?.orders?.length === 0"
                            #tab
                            (click)="setActiveTab(1)"
                        >
                            <span>{{ translation.outlookAddin.orders | transloco }} ({{ reservationDetail?.orders?.length }})</span>
                        </div>
                    </div>
                    <div
                        class="tabs-slider"
                        [ngStyle]="{
                            width: hrStyle.width + 'px',
                            transform: 'translateX(' + hrStyle.left + 'px)',
                        }"
                    ></div>
                </div>

                <div class="slides" #slider [class.disabled]="reservationDetail?.orders?.length === 0">
                    <div class="slide" #slide>
                        <addin-location-detail [resourceList]="reservationDetail.resources"></addin-location-detail>
                    </div>
                    <div class="slide" #slide>
                        <addin-order-detail [orderList]="reservationDetail.orders"> </addin-order-detail>
                    </div>
                </div>
            </div>
        } @else {
            <div class="not-found">
                <cue-icon [icon]="ICONS.cueClose"></cue-icon>
                {{ translation.outlookAddin.noResourceOnEvent | transloco }}
            </div>
        }
    }
}
