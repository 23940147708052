import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'imageDimensions',
  standalone: true,
})
export class ImageDimensionsPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: any, options: { width?: number; height?: number }): any {
    if (!url) return url;
    const isLocalRelativeUrl = url.startsWith('/');
    if (isLocalRelativeUrl) {
      return this.sanitizer.bypassSecurityTrustUrl(new URL(`${location.origin}${url}`).toString());
    }
    const finalUrl = new URL(url);
    if (options.height) finalUrl.searchParams.append('height', options.height.toString());
    if (options.width) finalUrl.searchParams.append('width', options.width.toString());
    const extension = finalUrl.pathname.split('.').pop();
    if (extension !== 'svg') {
      finalUrl.searchParams.append('format', 'webp');
    }

    return this.sanitizer.bypassSecurityTrustUrl(finalUrl.toString());
  }
}
