<div class="wrapper">
    @for (resource of resourceList; track resource; let i = $index) {
        <div class="accordion" [class.expanded]="isItemExpanded(i)">
            <div class="accordion-header" (click)="expandShrinkGroup(i)">
                <div class="first-part">
                    <p class="heading">
                        {{ resource.resourceDisplayNameForApp ?? resource.displayName }}
                    </p>

                    @switch (resource.responseStatus) {
                        @case ('accepted') {
                            <span class="accepted">{{ translation.common.accepted | transloco }}</span>
                        }
                        @case ('declined') {
                            <span class="canceled">{{ translation.common.cancelled | transloco }}</span>
                        }
                        @case ('tentative') {
                            <span class="tentative">{{ translation.common.tentative | transloco }}</span>
                        }
                    }
                </div>
                <div class="end-part">
                    <div class="arrow">
                        <cue-icon [icon]="ICONS.cueArrowDown"></cue-icon>
                    </div>
                </div>
            </div>
            @if (isItemExpanded(i)) {
                <div class="accordion-content">
                    <addin-resource-detail [resource]="resource"> </addin-resource-detail>
                </div>
            }
        </div>
    }
</div>
