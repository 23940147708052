import { Directive, effect, EventEmitter, inject, input, NgZone, OnInit, Output } from '@angular/core';
import { fromEvent, merge, Subject, takeUntil } from 'rxjs';
import { DOCUMENT } from '@angular/common';

export type ActivityChange = {
  idle: boolean;
  time: number;
};

@Directive({
  selector: '[cueInactivity]',
  standalone: true,
})
export class InactivityDirective {
  @Output() activityChange = new EventEmitter<ActivityChange>();
  cueInactivityEnabled = input<boolean>(true);
  reset$ = new Subject<void>();

  private _idleInterval;
  private _idleTime;
  private readonly zone = inject(NgZone);
  private readonly document = inject(DOCUMENT);

  enableChange = effect(() => {
    if (this.cueInactivityEnabled()) {
      this.enable();
    } else {
      this.disable();
    }
  });

  timerIncrement() {
    this._idleTime = this._idleTime + 10;
    this.activityChange.emit({
      idle: true,
      time: this._idleTime,
    });
  }

  resetTime() {
    this.activityChange.emit({
      idle: false,
      time: 0,
    });
    this._idleTime = 0;
    this.clear();
    this._idleInterval = setInterval(this.timerIncrement.bind(this), 10000); // kazdych 10 sec
  }

  clear() {
    if (this._idleInterval) {
      clearInterval(this._idleInterval);
    }
  }

  disable() {
    this.reset$.next();
    this.clear();
  }

  enable() {
    this.reset$.next();
    this.resetTime();
    this.zone.runOutsideAngular(() => {
      this.document.onload = this.resetTime.bind(this);
      merge(
        fromEvent(this.document, 'load'),
        fromEvent(this.document, 'mousedown'),
        fromEvent(this.document, 'touchstart'),
        fromEvent(this.document, 'click'),
        fromEvent(this.document, 'keydown'),
        fromEvent(window, 'scroll'),
      )
        .pipe(takeUntil(this.reset$))
        .subscribe(() => {
          this.resetTime();
        });
    });
  }
}
