/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/ban-ts-comment */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomUnsafeSanitizerDoNotUseMeService {
  private _tagWhitelist = {
    A: true,
    ABBR: false,
    B: true,
    S: false,
    BLOCKQUOTE: false,
    BODY: true,
    BR: true,
    CENTER: true,
    CODE: false,
    DD: true,
    DIV: true,
    DL: true,
    DT: true,
    EM: true,
    FONT: false,
    H1: true,
    H2: true,
    H3: true,
    H4: true,
    H5: true,
    H6: true,
    HR: false,
    I: true,
    IMG: false,
    LABEL: false,
    LI: true,
    OL: true,
    P: true,
    PRE: false,
    SMALL: true,
    SOURCE: false,
    SPAN: true,
    STRONG: true,
    SUB: false,
    SUP: false,
    TABLE: false,
    TBODY: false,
    TR: false,
    TD: false,
    TH: false,
    THEAD: false,
    UL: true,
    U: true,
    VIDEO: false,
  };
  private _contentTagWhiteList = { FORM: true };
  private _attributeWhitelist = {
    rel: true,
    align: true,
    color: true,
    controls: true,
    height: true,
    href: true,
    id: true,
    src: true,
    class: true,
    style: true,
    target: true,
    title: true,
    type: true,
    width: true,
  };
  private _cssWhitelist = {
    'letter-spacing': true,
    'line-height': true,
    'background-color': true,
    color: true,
    'font-size': true,
    'font-family': true,
    'font-weight': true,
    'text-align': true,
    'text-decoration': true,
    'text-decoration-line': true,
    'text-decoration-color': true,
    width: true,
  };
  private _schemaWhiteList = ['https:'];
  private _uriAttributes = { href: true, action: false };
  private _parser = new DOMParser();

  public SanitizeHtml(input: string, extraSelector?: string): string {
    input = input.trim();
    if (input === '') {
      return '';
    } //to save performance

    //firefox "bogus node" workaround for wysiwyg's
    if (input === '<br>') {
      return '';
    }
    //add "body" otherwise some tags are skipped, like <style>

    if (input.indexOf('<body') === -1) {
      input = '<body>' + input + '</body>';
    }

    const doc = this._parser.parseFromString(input, 'text/html');

    //DOM clobbering check (damn you firefox)
    if (doc.body.tagName !== 'BODY') {
      doc.body.remove();
    }
    if (typeof doc.createElement !== 'function') {
      (doc.createElement as any).remove();
    }

    const resultElement = this.makeSanitizedCopy(doc.body, doc, extraSelector);

    return resultElement.innerHTML.replace(/<br[^>]*>(\S)/g, '<br>\n$1').replace(/div><div/g, 'div>\n<div'); //replace is just for cleaner code
  }

  private makeSanitizedCopy(node: Node, doc: Document, extraSelector?: string): Element {
    let newNode;

    const isElementNode = node.nodeType === Node.ELEMENT_NODE;
    //@ts-ignore
    const isTagNameAllowed = this._tagWhitelist[node.nodeName] || this._contentTagWhiteList[node.nodeName];
    const matchesExtraSelector = extraSelector && (node as Element).matches(extraSelector);

    if (node.nodeType === Node.TEXT_NODE) {
      newNode = node.cloneNode(true);
    } else if (isElementNode && (isTagNameAllowed || matchesExtraSelector)) {
      const elNode = node as HTMLElement;
      //@ts-ignore
      if (this._contentTagWhiteList[elNode.tagName]) {
        newNode = doc.createElement('DIV'); //convert to DIV
      } else {
        newNode = doc.createElement(elNode.tagName);
      }

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < elNode.attributes.length; i++) {
        const attr = elNode.attributes[i];
        //@ts-ignore
        if (this._attributeWhitelist[attr.name]) {
          if (attr.name === 'style') {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let s = 0; s < elNode.style.length; s++) {
              const styleName = elNode.style[s];
              //@ts-ignore
              if (this._cssWhitelist[styleName]) {
                newNode.style.setProperty(styleName, elNode.style.getPropertyValue(styleName));
              }
            }
          } else {
            //@ts-ignore
            if (this._uriAttributes[attr.name]) {
              //if this is a "uri" attribute, that can have "javascript:" or something
              if (attr.value.indexOf(':') > -1 && !this.startsWithAny(attr.value, this._schemaWhiteList)) {
                continue;
              }
            }
            newNode.setAttribute(attr.name, attr.value);
          }
        }
      }

      for (let i = 0; i < elNode.childNodes.length; i++) {
        const subCopy = this.makeSanitizedCopy(node.childNodes[i], doc, extraSelector);
        //@ts-ignore
        newNode.appendChild(subCopy, false);
      }

      //remove useless empty spans (lots of those when pasting from MS Outlook)
      if (
        (newNode.tagName === 'SPAN' || newNode.tagName === 'B' || newNode.tagName === 'I' || newNode.tagName === 'U') &&
        newNode.innerHTML.trim() === ''
      ) {
        return doc.createDocumentFragment() as any;
      }
    } else {
      newNode = doc.createDocumentFragment();
    }
    return newNode as any;
  }

  private startsWithAny(str: any, substrings: any) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < substrings.length; i++) {
      // eslint-disable-next-line eqeqeq
      if (str.indexOf(substrings[i]) == 0) {
        return true;
      }
    }
    return false;
  }
}
