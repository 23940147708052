import {
  Attendee,
  BodyType,
  Location,
  OnlineMeetingInfo,
  PatternedRecurrence,
  Recipient,
  ResponseType,
} from '@microsoft/microsoft-graph-types';
import { ResourceAssignType } from './resources.models';

export enum OutlookReservationMode {
  NotReservationDetail,
  View,
  Edit,
}

export enum ReservationEventStatusEnum {
  Unknown,
  Confirmed,
  Cancelled,
  Tentative,
  UnconfirmEventCanceled,
}
export interface IReservationDetail {
  id?: string;
  iCalUid?: string;
  showAs: string;
  subject: string;
  start: IEventDateBM;
  end: IEventDateBM;
  isAllDay: boolean;
  cancelled: boolean;
  confirmed: boolean;
  unconfirmedEventCanceled: boolean;
  body: string;
  teams: boolean;
  teamsUrl?: string;
  confirmationInfo: IEventInfoConfirmationBm;
  resources: IEventInfoResourceResponseBm[];
  attendees: IEventInfoResponseAttendeeBm[];
  orders: IEventInfoOrderBm[];
}

export interface IEventDateBM {
  dateTime: Date;
  timeZone: string;
}

export interface IEventInfoConfirmationBm {
  confirmationRequired: boolean;
  notBefore: Date;
  until: Date;
}

export interface IEventInfoResourceResponseBm {
  resourceTypId: number;
  resourceDisplayNameForApp?: string;
  resourceTypeName?: string;
  isAssignedTo: ResourceAssignType;
  capacity?: number;
  id: number;
  guid: string;
  displayName: string;
  username: string;
  email: string;
  photos: IEventInfoResourcePhotoResponseBm[];
  responseStatus?: string;
  areaId: number;
  breadcrumb: IEventInfoBreadcrumbItem[];
  mapUrl: string;
  mapGeoData: string;
  tempProperties: IResourceAndResourceProperty[];
  properties: IEventInfoResourcePropertyBm[];
}

export interface IEventInfoResponseAttendeeBm {
  displayName: string;
  responseStatus: string;
  emailAddress: string;
}

export interface IEventInfoOrderBm {
  id: string;
  created: Date;
  code: string;
  note: string;
  items: any;
}

export interface IEventInfoResourcePhotoResponseBm {
  url: string;
  changed;
}

export interface IEventInfoBreadcrumbItem {
  id: number;
  url: string;
  changed: Date;
  displayName: string;
}

interface IResourceAndResourceProperty {
  id: string;
  resourceId: number;
  resourcePropertyId: string;
  resourceValueBoolean?: boolean;
  resourceValueNumber?: number;
  resourceValueDecimal?: number;
  resourceValueString?: string;
  resourcePropertyItemId?: string;
  resourcePropertyItemValue?: boolean;

  resource?: any;
  resourceProperty?: any;
  resourcePropertyItem?: any;
}

export interface IEventInfoResourcePropertyBm {
  dataTypeId: number;
  dataTypeName: string;
  valueNumber?: number;
  valueDecimal?: number;
  valueBoolean?: boolean;
  valueChoiceIds: string[];
  valueChoices: IEventInfoResourcePropertyChoiceBm[];
  valueText: string;
  name: string;
}

export interface IEventInfoResourcePropertyChoiceBm {
  name: string;
}

export interface IReservationItem {
  id: string;
  createdDateTime?: Date;
  lastModifiedDateTime?: Date;
  changeKey?: string;
  categories?: any[];
  transactionId?: null;
  originalStartTimeZone?: string;
  originalEndTimeZone?: string;
  iCalUId: string;
  reminderMinutesBeforeStart?: number;
  isReminderOn?: boolean;
  hasAttachments?: boolean;
  subject: string;
  bodyPreview?: string;
  importance?: string;
  sensitivity?: string;
  isAllDay?: boolean;
  isCancelled?: boolean;
  isOrganizer?: boolean;
  seriesMasterId?: null;
  showAs?: string;
  type?: string;
  webLink?: string;
  onlineMeetingUrl?: null;
  isOnlineMeeting?: boolean;
  onlineMeetingProvider?: string;
  allowNewTimeProposals?: boolean;
  occurrenceId?: string | null;
  isDraft?: boolean;
  hideAttendees?: boolean;
  responseStatus?: { response: ResponseType; time: string };
  body?: { contentType: BodyType; content: string };
  start?: { dateTime: Date; timeZone: string };
  end?: { dateTime: Date; timeZone: string };
  location?: Location;
  locations?: Location[];
  recurrence?: PatternedRecurrence | null;
  attendees?: Attendee[];
  organizer?: Recipient;
  onlineMeeting?: OnlineMeetingInfo;
}
