import { createSelector } from '@ngrx/store';
import { selectShared } from './shared.selectors';

import { allResources } from './resources.selectors';
import { Memoize, MemoizeExpiring } from 'typescript-memoize';
import { getNextQuarter, patchAvailabilityByWorkingHours, scheduleHelper } from '../utils';
import { add, differenceInMinutes, format, isWithinInterval, set, startOfDay } from 'date-fns';
import { CalendarAvailability, CalendarSchedulingSettings } from '@cue/calendars';
import { WorkingHours } from '../models';

export const favourites = createSelector(selectShared, (state) => state.favourites);

export const isFavouriteSelector = (id: string) =>
  createSelector(
    favourites,
    (_favourites) => _favourites.schedules.find((schedule) => schedule.resourceId.toUpperCase() === id.toUpperCase()) != null,
  );

class FavouritesHelper {
  @MemoizeExpiring(
    60000,
    (
      availabilityView: string,
      workingHours: WorkingHours,
      start: Date,
      timezoneName: string,
      schedulingSettings: CalendarSchedulingSettings,
    ) => {
      return (
        availabilityView +
        ':' +
        timezoneName +
        ':' +
        schedulingSettings?.minimumMeetingDurationInMinutes +
        ':' +
        schedulingSettings?.minimumMeetingDurationInMinutes +
        ':' +
        schedulingSettings?.maxAdvanceDays +
        ':' +
        schedulingSettings?.rejectOutsideWorkingHours +
        start.toISOString() +
        ':' +
        JSON.stringify(workingHours)
      );
    },
  )
  patchAvailabilityViewByWorkingHoursAndLimits(
    availabilityView: CalendarAvailability[],
    workingHours: WorkingHours,
    start: Date,
    resourceTimeZoneName: string,
    schedulingSettings: CalendarSchedulingSettings,
  ) {
    const patchedByWorkingHours = patchAvailabilityByWorkingHours(
      start,
      availabilityView,
      workingHours,
      resourceTimeZoneName,
      schedulingSettings,
    );

    const newNow = getNextQuarter(new Date());
    const earliestPossible = set(newNow, { seconds: 0, milliseconds: 0 });
    const transformed = patchedByWorkingHours.map((availabilityBit, index) => {
      const startTime = add(start, { minutes: index * 15 });

      if (set(startTime, { seconds: 0, milliseconds: 0 }) < earliestPossible) return CalendarAvailability.limited;
      return availabilityBit;
    });
    return transformed;
  }
}

const helper = new FavouritesHelper();

export const computedFavourites = createSelector(favourites, allResources, (_favs, _resources) => {
  if (_favs.loaded) {
    const mapped = _favs.schedules.map((schedule) => {
      const resource = _resources.data!.find((x) => x.id === schedule.resourceId)!;

      const resourceTimeZone = resource.timezone;
      const reservedTo = resource.conditions.reservedTo;

      let computedCalendarAvailability = schedule.availabilityView.split('') as CalendarAvailability[];

      computedCalendarAvailability = scheduleHelper.patchAvailabilityByRestrictions(
        resourceTimeZone,
        new Date(),
        computedCalendarAvailability,
        schedule.schedulingSettings,
        reservedTo,
      );

      computedCalendarAvailability = helper.patchAvailabilityViewByWorkingHoursAndLimits(
        computedCalendarAvailability,
        schedule.workingHours,
        _favs.start,
        resource.timezone,
        schedule.schedulingSettings,
      );

      let availableState: 'NOW' | 'SOON' | 'UNAVAILABLE';
      let start = _favs.start;
      let end: Date | null = null;
      let i = 0;

      if (computedCalendarAvailability[i] === CalendarAvailability.free) {
        // Je k dispozici ihned
        availableState = 'NOW';
        if (computedCalendarAvailability[i] === CalendarAvailability.free) {
          availableState = 'NOW';
          for (let j = i; j < computedCalendarAvailability.length; j++) {
            if (!(computedCalendarAvailability[j] === CalendarAvailability.free)) {
              end = add(_favs.start, { minutes: 15 * j });
              break;
            }
          }
          if (end == null) {
            end = add(_favs.start, { minutes: 15 * computedCalendarAvailability.length - 1 });
          }
        }
        const maxDuration = schedule.schedulingSettings?.maximumMeetingDurationInMinutes;
        if (maxDuration != null) {
          const duration = end != null ? differenceInMinutes(end, start) : 0;
          if (duration > maxDuration) {
            end = add(start, { minutes: maxDuration });
          }
        }

        return {
          availableState: availableState,
          from: start,
          to: end,
          resource: resource,
        };
      }

      for (i = 0; i < computedCalendarAvailability.length; i++) {
        if (computedCalendarAvailability[i] === CalendarAvailability.free) {
          start = add(_favs.start, { minutes: 15 * i });
          break;
        }
      }

      if (computedCalendarAvailability[i] === CalendarAvailability.free) {
        availableState = 'SOON';
        for (let j = i; j < computedCalendarAvailability.length; j++) {
          if (!(computedCalendarAvailability[j] === CalendarAvailability.free)) {
            end = add(_favs.start, { minutes: 15 * j });
            break;
          }
        }
        if (end == null) {
          end = add(_favs.start, { minutes: 15 * computedCalendarAvailability.length - 1 });
        }
        const maxDuration = schedule.schedulingSettings?.maximumMeetingDurationInMinutes;
        if (maxDuration != null) {
          const duration = end != null ? differenceInMinutes(end, start) : 0;
          if (duration > maxDuration) {
            end = add(start, { minutes: maxDuration });
          }
        }
        return {
          availableState: availableState,
          from: start,
          to: end,
          resource: resource,
        };
      }

      availableState = 'UNAVAILABLE';
      return {
        availableState: availableState,
        from: start,
        to: add(_favs.start, { minutes: 15 * computedCalendarAvailability.length }),
        resource: resource,
      };
    });
    return {
      favourites: mapped.filter((f) => f.resource?.conditions.reservedTo !== 'other'),
      loaded: _favs.loaded,
    };
  } else {
    return { favourites: [], loaded: true };
  }
});
