import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  constructor(private breakpointObs: BreakpointObserver) {}
  observePortrait(): Observable<BreakpointState> {
    return this.breakpointObs.observe('(orientation: portrait)');
  }
  observeMobile(): Observable<BreakpointState> {
    return this.breakpointObs.observe('(max-width: 1000px)');
  }
  observeMobileTopMenu(): Observable<BreakpointState> {
    return this.breakpointObs.observe('(max-width: 910px)');
  }
  isPortrait() {
    return this.observePortrait().pipe(map((x) => (x.matches ? true : false)));
  }
  isMobile() {
    return this.observeMobile().pipe(map((x) => (x.matches ? true : false)));
  }

  isMobileTopMenu() {
    return this.observeMobileTopMenu().pipe(map((x) => (x.matches ? true : false)));
  }
}
