import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cue-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LoadingComponent {
  @Input() positionX: 'left' | 'center' | 'right' = 'center';
  @Input() positionY: 'top' | 'center' | 'bottom' = 'center';

  get positionClasses(): string {
    return 'position-x-' + this.positionX + ' position-y-' + this.positionY;
  }
}
