import { ApiRequest } from './requests';
import { AssistUserSettingsBM } from '../models/settings/assist-user-settings.model';

export function getUserSettings(): ApiRequest<void, AssistUserSettingsBM> {
  return {
    url: '/api/users/me/settings',
    method: 'get',
  };
}

export function setUserSettings(settings: AssistUserSettingsBM): ApiRequest<AssistUserSettingsBM, void> {
  return {
    url: '/api/users/me/settings',
    method: 'post',
    payload: settings,
  };
}
