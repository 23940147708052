<header class="navigation">
    <div class="buttons">
        @if ((currentOutlookReservationMode$ | async) === outlookReservationMode.Edit) {
            <button class="small" (click)="navigateToReservationList()">
                {{ translation.outlookAddin.changeLocation | transloco }}
            </button>
        }
    </div>
    <div (click)="mobileHamburger = !mobileHamburger" class="hamburger">
        @if (mobileHamburger) {
            <cue-icon [icon]="ICONS.cueClose"></cue-icon>
        }
        @if (!mobileHamburger) {
            <cue-icon [icon]="ICONS.cueUser"></cue-icon>
        }
    </div>
</header>

<div class="menu-nav" [class.opened]="mobileHamburger">
    <div class="menu-wrapper">
        @if (!isUserLoggedIn()) {
            <div class="menu-item" (click)="loginWithMsDialog()">
                {{ translation.outlookAddin.login | transloco }}
            </div>
        }
        @if (isUserLoggedIn()) {
            @if (userInfo$ | async; as userInfo) {
                @if (!userInfo.loading && userInfo.data) {
                    <div class="user-info">
                        {{ userInfo.data.name }} <span class="email">({{ userInfo.data.email }})</span>
                    </div>
                }
                <!--                <div class="menu-item" (click)="logout()">-->
                <!--                    {{ translation.outlookAddin.logout | transloco }}-->
                <!--                </div>-->
                <hr />
            }
        }

        <div class="data-item language-label">
            <div class="language-wrapper">
                <assist-dropdownlist
                    [data]="languageItems"
                    [formControl]="currentLanguageControl"
                    [popupSettings]="{
                        popupClass: 'language-popup',
                    }"
                    textField="text"
                    valueField="text"
                >
                    <ng-template let-dataItem valueTemplate>
                        <img [src]="'assets/images/flags/' + dataItem?.flag" alt="language-flag" class="flag" height="30" width="30" />
                    </ng-template>
                    <ng-template itemTemplate let-dataItem>
                        <img [src]="'assets/images/flags/' + dataItem.flag" alt="language-flag" class="flag" height="30" width="30" />
                        {{ dataItem.text }}
                    </ng-template>
                </assist-dropdownlist>
            </div>
        </div>
    </div>
</div>
