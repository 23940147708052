import { createAction, props } from '@ngrx/store';
import { UserInfo } from '@outlook-addin/cue-http';

export const loadUserInfo = createAction('[UserInfo] Load');

export const userInfoLoadSuccess = createAction('[UserInfo] Load success', props<{ userInfo: UserInfo }>());

export const userInfoLoadError = createAction('[UserInfo] Load error', props<{ error: string }>());

export const clearUserInfo = createAction('[UserInfo] Clear');

export const userInfoCleared = createAction('[UserInfo] Cleared');
