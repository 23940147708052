import { Directive, forwardRef, OnInit, HostBinding } from '@angular/core';
import { Dimensions, Display } from '../models';
import { ImageStateService } from '../services';

@Directive({
  selector: '[cueProgressiveImagePlaceholder]',
  standalone: true,
  providers: [
    {
      provide: Dimensions,
      useExisting: forwardRef(() => ProgressiveImagePlaceholderDirective),
    },
  ],
})
export class ProgressiveImagePlaceholderDirective implements OnInit, Dimensions {
  @HostBinding('style.display') display = Display.flex;
  @HostBinding('style.width') width: number;
  @HostBinding('style.height') height: number;
  @HostBinding('style.max-height') maxHeight: number;

  constructor(private imageState: ImageStateService) {}

  ngOnInit() {
    this.imageState.isLoading$.subscribe((isLoading) => {
      this.display = isLoading ? Display.flex : Display.none;
    });
  }
}
