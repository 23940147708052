import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { STORAGE_KEYS, TouchOneHttpService } from '@outlook-addin/cue-http';
import { UserInfoActions } from '../actions';
import { map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromModule from '../reducers';

@Injectable()
export class UserInfoEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private touchoneHttpService: TouchOneHttpService,
    private store: Store<fromModule.State>,
  ) {}

  loadUserInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserInfoActions.loadUserInfo),
      switchMap(() => {
        const data$ = this.touchoneHttpService.getMeInfo();
        return data$.pipe(
          tap((x) => {
            localStorage.setItem(STORAGE_KEYS.outlookUsername, x.data?.email);
          }),
          map((result) =>
            result.error
              ? UserInfoActions.userInfoLoadError({ error: result.error })
              : UserInfoActions.userInfoLoadSuccess({ userInfo: result.data }),
          ),
        );
      }),
    );
  });

  clearDataEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserInfoActions.clearUserInfo),
      tap(() => {
        localStorage.removeItem(STORAGE_KEYS.code);
        localStorage.removeItem(STORAGE_KEYS.refreshTokenMicrosoft);
        localStorage.removeItem(STORAGE_KEYS.refreshToken);
        localStorage.removeItem(STORAGE_KEYS.accessToken);
        localStorage.removeItem(STORAGE_KEYS.accessTokenMicrosoft);
        this.router.navigate(['login']);
      }),
      map(() => UserInfoActions.userInfoCleared()),
    ),
  );
}
