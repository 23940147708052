import { Pipe, PipeTransform } from '@angular/core';
import { IResourceFilterUpdateCrate } from '@outlook-addin/cue-http';

@Pipe({
  name: 'filterCount',
  standalone: true,
})
export class FilterCountPipePipe implements PipeTransform {
  transform(filter: IResourceFilterUpdateCrate): number {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!filter) {
      return 0;
    }

    let resultCount = filter.additionalFilterList?.length ?? 0;
    if (filter.showOnlyAvailable) {
      resultCount = resultCount + 1;
    }
    if (filter.resourceName != null && filter.resourceName != '') {
      resultCount = resultCount + 1;
    }

    return resultCount;
  }
}
