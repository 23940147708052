import { Pipe, PipeTransform } from '@angular/core';
import { cueBlock, cueGroup, cueIcon, cueUser, cueArrowDown } from '@cue/ui/icons';
import { ResourceAssignType } from '@outlook-addin/cue-http';
import { AddinConfigService } from '@outlook-addin/shared';

@Pipe({
  name: 'resourceExternalUrl',
  standalone: true,
})
export class ResourceExternalUrlPipe implements PipeTransform {
  constructor(private configService: AddinConfigService) {}

  transform(resourceGuid: string): string {
    if (resourceGuid) {
      return `${this.configService.value.configuration.assistURL}/dashboard/resource-info?id=${resourceGuid}`;
    }
    return null;
  }
}
