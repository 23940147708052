import { Pipe, PipeTransform } from '@angular/core';
import { cueBlock, cueGroup, cueIcon, cueUser, cueArrowDown } from '@cue/ui/icons';
import { ResourceAssignType } from '@outlook-addin/cue-http';

@Pipe({
  name: 'assignTo',
  standalone: true,
})
export class AssignToPipe implements PipeTransform {
  transform(assignTo: ResourceAssignType): cueIcon {
    switch (assignTo) {
      case ResourceAssignType.Mine:
        return cueUser;
      case ResourceAssignType.Ours:
        return cueGroup;
      case ResourceAssignType.Restricted:
        return cueBlock;
      case ResourceAssignType.Free:
        return null;
      default:
        return null;
    }
  }
}
