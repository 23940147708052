import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  _buttons$: BehaviorSubject<TemplatePortal<TemplateRef<unknown>>> = new BehaviorSubject<TemplatePortal<TemplateRef<unknown>>>(null);

  _mobileOnlyButtons$: BehaviorSubject<TemplatePortal<TemplateRef<unknown>>> = new BehaviorSubject<TemplatePortal<TemplateRef<unknown>>>(
    null,
  );
  _desktopOnlyButtons$: BehaviorSubject<TemplatePortal<TemplateRef<unknown>>> = new BehaviorSubject<TemplatePortal<TemplateRef<unknown>>>(
    null,
  );
  buttons$ = this._buttons$.asObservable();
  mobileOnlyButtons$ = this._mobileOnlyButtons$.asObservable();
  desktopOnlyButtons$ = this._desktopOnlyButtons$.asObservable();

  _title$: BehaviorSubject<string> = new BehaviorSubject('');
  title$ = this._title$.asObservable();

  setTitle(title: string) {
    this._title$.next(title);
  }

  setButtons(portal: TemplatePortal<TemplateRef<unknown>>, where: 'mobile' | 'desktop' | 'both' = 'both') {
    if (where === 'mobile') {
      this._mobileOnlyButtons$.next(portal);
    } else if (where === 'desktop') {
      this._desktopOnlyButtons$.next(portal);
    } else {
      this._buttons$.next(portal);
    }
  }

  removeButtons() {
    this._buttons$.next(null);
    this._mobileOnlyButtons$.next(null);
    this._desktopOnlyButtons$.next(null);
  }
}
