import { createReducer, on } from '@ngrx/store';
import { AccountActions } from '../actions';

export type State = {
  authCode: string;
  msUserIsLoggedin: boolean;
};

export const initialState: State = {
  authCode: null,
  msUserIsLoggedin: false,
};

export const reducer = createReducer(
  initialState,
  on(AccountActions.setAuthCode, (state, action) => ({
    ...state,
    authCode: action.authCode,
  })),
  on(AccountActions.authCodeReceived, (state, action) => ({
    ...state,
    msUserIsLoggedin: true,
  })),
);
