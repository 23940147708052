@if (!showFilter) {
    <div class="safe-padding filter-button">
        <button (click)="showFilter = !showFilter" class="small">
            <cue-icon [icon]="cueFilter"></cue-icon>
            {{ translation.outlookAddin.openFilters | transloco }}
        </button>

        @if (reqCount$ | async; as requirementsCount) {
            <div class="remove-filters">
                {{ requirementsCount }}
                {{ translation.outlookAddin.activeFilterCount | transloco }}
                <cue-icon [icon]="cueClose" (click)="resetAllFilters()"></cue-icon>
            </div>
        }
    </div>
}
@if (showFilter) {
    <addin-resource-filter
        [filterCount]="reqCount$ | async"
        [hierarchyAreas]="hierarchyAreas$ | async"
        [currentFilter]="currentResourceFilter$ | async"
        [areas]="areas$ | async"
        [resourceFilters]="resourcesFilter$ | async"
        (refreshFilter)="showFilter = false"
    >
    </addin-resource-filter>
}
<addin-resource-list
    (refresh)="onRefresh()"
    [hidden]="showFilter"
    [resourceFilter]="currentResourceFilter$ | async"
    [resourceList]="resourceList$ | async"
    [groupedResourceList]="groupedResourceList$ | async"
>
</addin-resource-list>
