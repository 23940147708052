export enum CSCode {
  CueSolution = 'CS0604',
  ResourcePremium = 'CS0600',
  AssistWorkplace = 'CS0601',
  AssistCustom = 'CS0606',
  POIStandard = 'CS0621',
  CueSaaS = 'CS0622',
  AnalyticsResBase = 'CS0630',
  AnalyticsRes01 = 'CS0632',
  AnalyticsNaviBase = 'CS0631',
  AnalyticsNavi01 = 'CS0633',
  ResourceBasic = 'CS0640',
  ResourceStandard = 'CS0641',
  POIBasic = 'CS0642',
  KioskBasic = 'CS0637',
  KioskStandard = 'CS0638',
  KioskPremium = 'CS0639',
}
