import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IEventInfoResourceResponseBm } from '@outlook-addin/cue-http';
import { translation } from '@assist/shared/translations';

import { cueArrowDown, IconComponent } from '@cue/ui/icons';
import { ResourceDetailComponent } from '../resource-detail/resource-detail.component';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'addin-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.scss'],
  standalone: true,
  imports: [ResourceDetailComponent, TranslocoModule, IconComponent],
})
export class LocationDetailComponent implements OnChanges {
  ICONS = {
    cueArrowDown,
  };
  translation = translation;

  expandItemIndexList: number[] = [];
  @Input() resourceList: IEventInfoResourceResponseBm[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resourceList'] && this.resourceList?.length === 1) {
      this.expandItemIndexList.push(0);
    }
  }

  isItemExpanded(itemIndex: number) {
    return this.expandItemIndexList.indexOf(itemIndex) > -1;
  }

  expandShrinkGroup(itemIndex: number) {
    const itemIxdInList = this.expandItemIndexList.indexOf(itemIndex);
    if (itemIxdInList > -1) {
      this.expandItemIndexList.splice(itemIxdInList, 1);
    } else {
      this.expandItemIndexList.push(itemIndex);
    }
  }
}
