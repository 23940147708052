import { Component, EventEmitter, Input, NgZone, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IResourceFilterUpdateCrate, IResourceListItem, ResourceAssignType } from '@outlook-addin/cue-http';
import { Store } from '@ngrx/store';
import * as fromModule from '../ngrx/reducers';
import { ResourcesActions } from '../ngrx/actions';
import { NavigationDetailInfo } from '@assist/navigation/services';
import { debounceTime, distinctUntilChanged, forkJoin, Observable } from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Pack } from '@assist/shared/data';
import {
  cueAction,
  cueArrowRight,
  cueClose,
  cueExternalLink,
  cueInfo,
  cueMore,
  cueRefresh,
  cueSearch,
  cueArrowDown,
  IconComponent,
} from '@cue/ui/icons';
import { AddinConfigService } from '@outlook-addin/shared';
import { translation } from '@assist/shared/translations';
import AppointmentCompose = Office.AppointmentCompose;
import EmailUser = Office.EmailUser;

import LocationDetails = Office.LocationDetails;
import { TextboxComponent } from '@cue/assist/ui/inputs';
import { LoadingComponent } from '@cue/loading';
import { TranslocoModule } from '@ngneat/transloco';
import { ResourceSummaryDetailComponent } from '../resource-summary-detail/resource-summary-detail.component';
import { AssignToPipe } from '../shared/pipes';
import { ResourceAvailabilityComponent } from '../resource-availability/resource-availability.component';
import { CommonModule } from '@angular/common';
import { LocalizedStringPipe } from '@cue/utilities';

@Component({
  selector: 'addin-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TextboxComponent,
    LoadingComponent,
    TranslocoModule,
    IconComponent,
    ResourceAvailabilityComponent,
    AssignToPipe,
    ResourceSummaryDetailComponent,
    ReactiveFormsModule,
    LocalizedStringPipe,
  ],
})
export class ResourceListComponent implements OnChanges {
  ICONS = {
    cueClose,
    cueAction,
    cueSearch,
    cueArrowRight,
    cueExternalLink,
    cueRefresh,
    cueMore,
    cueArrowDown,
    cueInfo,
  };
  protected readonly translation = translation;
  resourceAssignType = ResourceAssignType;
  timelineDetailItemId: string;

  search = new FormControl<string>('', { nonNullable: true });

  selectedAttendeeEmailList: string[] = [];
  selectedResourceIdList: number[] = [];
  filterUpdate: IResourceFilterUpdateCrate = {
    showOnlyAvailable: false,
    resourceName: null,
    additionalFilterList: [],
  };

  resourceDetail$: Observable<Pack<NavigationDetailInfo>>;

  selectedResourceDetail: IResourceListItem;

  @Input() resourceFilter: IResourceFilterUpdateCrate;
  @Input() groupedResourceList: Pack<any[]>;
  @Input() resourceList: Pack<IResourceListItem[]>;

  @Output() refresh = new EventEmitter<void>();
  constructor(
    private store: Store<fromModule.State>,
    private configService: AddinConfigService,
    private zone: NgZone,
  ) {
    this.resourceDetail$ = this.store.select(fromModule.selectResourceDetail);
    this.search.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((x) => {
      this.searchTextChanged(x);
    });

    if (Office.context.mailbox) {
      Office.context.mailbox.item.addHandlerAsync(
        Office.EventType.AppointmentTimeChanged,

        this.handleItemChange.bind(this),
      );
      Office.context.mailbox.item.addHandlerAsync(Office.EventType.EnhancedLocationsChanged, this.handleLocationChange.bind(this));
      const itemData = Office.context.mailbox.item as AppointmentCompose;
      this.performAppointmentAction(itemData);
    }
  }

  changeCurrentOpenedTimelineDetail(id: string, event: MouseEvent) {
    if (this.timelineDetailItemId === id) {
      this.timelineDetailItemId = null;
    } else {
      this.timelineDetailItemId = id;
    }
    event.stopPropagation();
  }

  handleLocationChange(eventArgs: any) {
    const locationList: { email: string; resourceId: number }[] = eventArgs.enhancedLocations.map((x) => {
      return {
        email: x.displayName,
        resourceId: this.resourceList?.data.find(
          (y) =>
            y.resourceUserName === x.displayName ||
            y.resourceUserName === x.emailAddress ||
            y.resourceDisplayName === x.displayName ||
            y.resourceDisplayNameForApp === x.displayName,
        ).resourceId,
      };
    });

    this.updateSelectedResourceIdList(locationList);
  }

  private updateSelectedResourceIdList(data: { email: string; resourceId: number }[]) {
    const itemForSelectList = data.filter((x) => this.selectedResourceIdList.findIndex((y) => y === x.resourceId) === -1);

    const itemForDeselectList = this.selectedResourceIdList.filter((x) => data.findIndex((y) => y.resourceId === x) === -1);

    itemForDeselectList.forEach((item) => {
      const index: number = this.selectedResourceIdList.indexOf(item);
      this.selectedResourceIdList.splice(index, 1);
    });

    itemForSelectList.forEach((item) => {
      this.selectedResourceIdList.push(item.resourceId);
    });
  }

  handleItemChange(eventArgs: any) {
    this.zone.run(() => {
      this.updateResourceFilter(eventArgs.start, eventArgs.end);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resourceList'] && this.resourceList?.data?.length > 0 && this.selectedAttendeeEmailList?.length > 0) {
      this.selectedResourceIdList = this.resourceList.data
        .filter((x) => this.selectedAttendeeEmailList.findIndex((a) => a === x.resourceUserName) > -1)
        .map((x) => x.resourceId);
    }

    if (changes['resourceFilter'] && this.resourceFilter) {
      this.filterUpdate = {
        ...this.filterUpdate,
        resourceName: this.resourceFilter.resourceName ?? '',
        showOnlyAvailable: this.resourceFilter.showOnlyAvailable != null ? this.resourceFilter.showOnlyAvailable : false,
      };
      this.search.setValue(this.filterUpdate.resourceName);
    }
  }

  showOnlyAvailable() {
    this.filterUpdate = {
      ...this.filterUpdate,
      showOnlyAvailable: !this.filterUpdate.showOnlyAvailable,
    };
    this.store.dispatch(
      ResourcesActions.updateOnlyAvailableFilter({
        onlyAvailable: this.filterUpdate.showOnlyAvailable,
      }),
    );
    localStorage.setItem('showOnlyAvailableFilter', this.filterUpdate.showOnlyAvailable === true ? 'true' : 'false');
  }

  private searchTextChanged(data: string) {
    this.filterUpdate = {
      ...this.filterUpdate,
      resourceName: data,
    };
    this.store.dispatch(
      ResourcesActions.searchTextChanged({
        searchText: this.filterUpdate.resourceName,
      }),
    );
  }

  selectDeselectResource(resourceId: number, event: MouseEvent, assignTo: ResourceAssignType) {
    if (!assignTo || assignTo === ResourceAssignType.Restricted) {
      return;
    }
    this.selectDeselectResourceFromEvent(resourceId);
    event.stopPropagation();
  }

  selectDeselectResourceFromDetail(resourceId: number) {
    this.selectDeselectResourceFromEvent(resourceId);
    this.selectedResourceDetail = null;
  }

  private selectDeselectResourceFromEvent(resourceId: number) {
    const index: number = this.selectedResourceIdList.indexOf(resourceId);
    let shouldBeAttendeeSelected = false;
    if (index !== -1) {
      this.selectedResourceIdList.splice(index, 1);
    } else {
      this.selectedResourceIdList.push(resourceId);
      shouldBeAttendeeSelected = true;
    }
    this.selectDeselectOutlookAttendees(this.getResourceListItemById(resourceId), shouldBeAttendeeSelected);
  }

  isResourceSelected(resourceId: number) {
    return this.selectedResourceIdList.indexOf(resourceId) > -1;
  }

  showResourceDetail(resourceId: number | null, event: MouseEvent) {
    if (!resourceId) {
      this.selectedResourceDetail = null;
    } else {
      this.selectedResourceDetail = this.resourceList.data.filter((x) => x.resourceId === resourceId)[0];
      this.store.dispatch(ResourcesActions.loadResourceDetail({ resourceId: this.selectedResourceDetail.resourceId }));
    }
    event.stopPropagation();
  }

  navigateToResourceDetailInAssist(resourceGuid: string, event: MouseEvent) {
    window.open(this.configService.value.configuration.assistURL + '/dashboard/resource-info?id=' + resourceGuid, '_blank');
    event.stopPropagation();
  }

  private updateResourceFilter(start?: string, end?: string) {
    this.store.dispatch(
      ResourcesActions.updateStartEndFilter({
        start: new Date(start),
        end: new Date(end),
      }),
    );
  }

  private selectDeselectOutlookAttendees(attendee: EmailUser, shouldBeSelected: boolean) {
    Office.context.mailbox.item.enhancedLocation.getAsync((x) => {
      this.zone.run(() => {
        const currentAttendeeList: LocationDetails[] = x.value;
        let updatedAttendeeList: LocationDetails[] = [];
        if (shouldBeSelected) {
          updatedAttendeeList = [
            ...currentAttendeeList,
            {
              displayName: attendee.displayName,
              emailAddress: attendee.emailAddress,
              locationIdentifier: {
                id: attendee.emailAddress,
                type: 'room',
              },
            },
          ];
          Office.context.mailbox.item.enhancedLocation.addAsync(
            updatedAttendeeList.map((x) => {
              return x.locationIdentifier;
            }),
          );
        } else {
          const attendeeListToBeRemoved = currentAttendeeList.filter((x) => x.emailAddress == attendee.emailAddress);
          Office.context.mailbox.item?.enhancedLocation.removeAsync(
            attendeeListToBeRemoved.map((x) => {
              return x.locationIdentifier;
            }),
          );
        }
      });
    });
  }

  onDetailRefresh() {
    this.store.dispatch(ResourcesActions.loadResourceDetail({ resourceId: this.selectedResourceDetail.resourceId }));
  }

  private getResourceListItemById(resourceId: number) {
    const selectedResourceItem = this.resourceList.data.filter((x) => x.resourceId === resourceId)[0];

    return {
      displayName: selectedResourceItem.resourceDisplayNameForApp ?? selectedResourceItem.resourceDisplayName,
      emailAddress: selectedResourceItem.resourceUserName,
    };
  }

  private performAppointmentAction(item: AppointmentCompose) {
    const obs0 = new Observable<LocationDetails[]>((observer) => {
      Office.context.mailbox.item.enhancedLocation.getAsync((x) => {
        observer.next(x.value);
        observer.complete();
      });
    });

    const obs1 = new Observable<any>((observer) => {
      item.start.getAsync((x) => {
        observer.next(x.value);
        observer.complete();
      });
    });

    const obs2 = new Observable<any>((observer) => {
      item.end.getAsync((x) => {
        observer.next(x.value);
        observer.complete();
      });
    });

    return forkJoin([obs1, obs2, obs0]).subscribe(([start, end, resourceList]) => {
      this.updateResourceFilter(start, end);

      this.selectedResourceIdList = this.resourceList.data
        ?.filter((x) => resourceList.findIndex((a) => a.emailAddress === x.resourceUserName) > -1)
        .map((x) => x.resourceId);

      this.selectedAttendeeEmailList = resourceList.map((x) => x.emailAddress);
    });
  }

  expandShrinkGroup(itemIndex: number) {
    const itemIxdInList = this.shrinkItemIndexList.indexOf(itemIndex);
    if (itemIxdInList > -1) {
      this.shrinkItemIndexList.splice(itemIxdInList, 1);
    } else {
      this.shrinkItemIndexList.push(itemIndex);
    }
  }

  isItemShrinked(itemIndex: number) {
    return this.shrinkItemIndexList.indexOf(itemIndex) > -1;
  }

  shrinkItemIndexList: number[] = [];
}
