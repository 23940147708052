import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, switchMap, filter } from 'rxjs/operators';

import * as ColleagueActions from '../actions/colleague.actions';
import { forkJoin } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from '../services';
import { CalendarsService } from '@cue/calendars';

@Injectable()
export class ColleagueEffects {
  loadColeague$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ColleagueActions.loadColleague),
      filter((a) => a != null && a.identifier != null),
      switchMap((action) =>
        forkJoin([
          this.calendarsService.getCollegueInfo(action.identifier),
          this.calendarsService.getImageUrl(action.identifier).pipe(map((url) => this.sanitizer.bypassSecurityTrustUrl(url))),
        ]),
      ),
      map(([colleague, safeurl]) =>
        ColleagueActions.colleagueLoaded({
          colleague: { ...colleague, base64Image: safeurl },
        }),
      ),
    ),
  );

  constructor(
    private sanitizer: DomSanitizer,
    private actions$: Actions,
    private configService: ConfigService,
    private calendarsService: CalendarsService,
  ) {}
}
