import { getLocalization, Localization } from '@cue/translate';
import { en } from './en';

export const translation = getLocalization<typeof en>(en);
export const localizations: Localization[] = [
  { language: 'cs', valueGetter: () => import('./cs').then((x) => x.cs) },
  { language: 'en', valueGetter: () => import('./en').then((x) => x.en) },
  { language: 'de', valueGetter: () => import('./de').then((x) => x.de) },
  { language: 'fr', valueGetter: () => import('./fr').then((x) => x.fr) },
  { language: 'es', valueGetter: () => import('./es').then((x) => x.es) },
  { language: 'ca', valueGetter: () => import('./ca').then((x) => x.ca) },
  { language: 'ua', valueGetter: () => import('./ua').then((x) => x.ua) },
];
