import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { AzureAuthService } from '@outlook-addin/cue-http';
import { AccountActions, UserInfoActions } from '../actions';
import { map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromModule from '../reducers';

@Injectable()
export class AccountEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private azureHttpService: AzureAuthService,
    private store: Store<fromModule.State>,
    private zone: NgZone,
  ) {}

  loadTokenRefreshTokenCue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.loadTokenRefreshToken),
      switchMap((action) => {
        const data$ = this.azureHttpService.getTokenByAuthCode();
        return data$.pipe(
          tap(() => {
            if (action.route) {
              this.router.navigateByUrl('main-start');
            }
          }),
          mergeMap(() => {
            // this.zone.run(()=>{
            //   this.router.navigate(['../main-start']);
            // })
            return [AccountActions.tokenWithRefreshTokenSaved(), UserInfoActions.loadUserInfo()];
          }),
        );
      }),
    );
  });
}
