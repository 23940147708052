@if (src !== null) {
    <cue-progressive-image
        [imgWidth]="imgWidth"
        [imgHeight]="imgHeight"
        [imgMaxHeight]="imgMaxHeight"
        [ngStyle]="{ 'max-width': imgMaxWidth }"
    >
        <img
            [class.!object-cover]="cover"
            cueProgressiveImage
            [src]="src | imageDimensions: { width: width, height: loadHeight }"
            [class]="imageClass"
        />
        <div class="placeholder" cueProgressiveImagePlaceholder>
            <cue-loading></cue-loading>
        </div>
    </cue-progressive-image>
}
