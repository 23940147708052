import { AddinConfigService } from '@outlook-addin/shared';
import { ENVIRONMENT_INITIALIZER, importProvidersFrom, inject } from '@angular/core';
import { DialogModule } from '@angular/cdk/dialog';
import { provideState, provideStore } from '@ngrx/store';
import { ROOT_REDUCERS } from './shared/reducers';
import { environment } from '../environments/environment';
import * as effects from './ngrx/effects';
import { RouterSerializer } from './shared/models/common.models';
import { reducers } from './ngrx/reducers';
import { configure, Localization, LOCALIZATIONS, provideLocalization } from '@cue/translate';
import { localizations } from '@assist/shared/translations';
import { TranslocoService } from '@ngneat/transloco';
import { coreConfig } from './core/core.config';
import { provideRouter, withDebugTracing } from '@angular/router';
import { MainMenuContainerComponent } from './navigations';
import { LoginComponent } from './login/login.component';
import { AuthorizeComponent } from './authorize/authorize.component';
import { LoginGuard } from './shared/guards';
import { MeetingFinderComponent } from './meeting-finder/meeting-finder.component';
import { AppointmentEditComponent } from './appointment-edit/appointment-edit.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReservationListComponent } from './reservation-list/reservation-list.component';
import { ReservationCannotFetchComponent } from './reservation/reservation-cannot-fetch/reservation-cannot-fetch.component';
import { ReservationContainerComponent } from './reservation/reservation-container/reservation-container.component';
import { MainStartComponent } from './main-start/main-start.component';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideRouterStore } from '@ngrx/router-store';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePipes } from '@assist/ui/pipes';
import { provideGoogleAnalytics } from '@cue/google-analytics';

const REDUCERS = reducers;

export function appConfig(service: AddinConfigService) {
  return {
    providers: [
      providePipes(),
      coreConfig().providers,
      provideGoogleAnalytics({
        keyFactory: () => {
          const configService = inject(AddinConfigService);
          return configService.value.configuration.googleAnalyticsEnabled && configService.value.configuration.googleAnalyticsKey != null
            ? configService.value.configuration.googleAnalyticsKey
            : undefined;
        },
      }),
      provideRouter(
        [
          {
            path: 'taskpane.html',
            redirectTo: '',
          },
          {
            path: 'index.html',
            redirectTo: '',
          },
          {
            path: '',
            component: MainMenuContainerComponent,
            children: [
              {
                path: '',
                // component: AuthorizeComponent,
                redirectTo: 'main-start',
                pathMatch: 'full',
              },
              {
                path: 'Authorize2',
                redirectTo: 'authorize',
                pathMatch: 'full',
              },
              {
                path: 'login',
                component: LoginComponent,
              },
              {
                path: 'authorize',
                component: AuthorizeComponent,
              },
              {
                path: 'meeting-finder',
                canActivate: [LoginGuard],
                component: MeetingFinderComponent,
              },
              {
                path: 'appointment-edit',
                component: AppointmentEditComponent,
              },
              {
                path: 'dashboard',
                component: DashboardComponent,
              },
              {
                path: 'reservations',
                canActivate: [LoginGuard],
                component: ReservationListComponent,
              },
              {
                path: 'reservation/cannot-fetch',
                canActivate: [LoginGuard],
                component: ReservationCannotFetchComponent,
              },
              {
                path: 'reservation/:reservationId',
                canActivate: [LoginGuard],
                component: ReservationContainerComponent,
              },
              {
                path: 'main-start',
                canActivate: [LoginGuard],
                component: MainStartComponent,
              },
            ],
          },

          {
            path: '**',
            redirectTo: 'error',
          },
        ],
        withDebugTracing(),
      ),
      provideRouterStore({
        serializer: RouterSerializer,
      }),
      provideStore(ROOT_REDUCERS, {}),
      provideState({
        name: 'app',
        reducer: REDUCERS,
      }),
      provideStoreDevtools({
        logOnly: environment.production,
        connectInZone: true,
      }),

      provideEffects([effects.AccountEffects, effects.ReservationEffects, effects.ResourcesEffects, effects.UserInfoEffects]),
      importProvidersFrom(DialogModule),
      provideAnimationsAsync(),
      provideLocalization({
        localizations: localizations,
        translocoConfig: {
          availableLangs: service.value.configuration?.languages.filter((x) => x.activeInAssist).map((x) => x.code) ?? [],
          defaultLang: service.value.configuration.languages.find((x) => x.default).code ?? 'en',
          prodMode: false,
          reRenderOnLangChange: true,
          fallbackLang: service.value.configuration?.languages.find((x) => x.default).code ?? 'en',
          missingHandler: {
            useFallbackTranslation: true,
          },
        },
      }),
      {
        provide: AddinConfigService,
        useValue: service,
      },
      {
        provide: ENVIRONMENT_INITIALIZER,
        multi: true,
        useValue() {
          const translocoService = inject(TranslocoService);
          const localizations = inject(LOCALIZATIONS) as unknown as Localization[][];
          configure(translocoService, localizations);
        },
      },
    ],
  };
}
